<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <a
      href="#"
      v-on:click="print()"
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      >imprimir</a
    >
    <router-link
      class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat"
      to="/"
      >Volver al home</router-link
    >
  </div>
</template>

<script>
export default {
  name: "OrderConfirmationFooter",
};
</script>

<style></style>
