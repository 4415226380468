<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <h2 class="c-text-left">Importante</h2>
      <div class="c-text-medium">
        Antes de comprar, coloca SHARF delante de tu nombre en la tienda
        online y no olvides de colocar nuestra dirección de Miami.
      </div>
      <div
        class="c-content--box-shadow c-content--box-shadow-hover d-flex p-1 mt-2 mb-2 border-radius"
      >
        <div
          class="collection c-collection c-collection--not-hover c-collection--card c-collection--card__item c-collection--card__item__full m-0"
        >
          <div class="collection-item collection-item--title" v-if="user">
            Sharf {{ user.fullName }}
          </div>
          <div id="TextCopyLink" class="collection-item font-weight-light">
            <p class="copyLink">DORAL:</p>
            <p class="copyLink">2227 NW 79Th Ave.</p>
            <p class="copyLink">Doral,Fl.33198.</p>
            <p class="copyLink">Tel: +1 305-599-0115</p>
          </div>
          <div class="collection-item font-weight-bold">
            <textarea
              id="txt-direccion-envio"
              class="c-hidden-copy"
              v-model="message"
            ></textarea>
            <a
              href="javascript:void(0)"
              id="CopyLink"
              class="font-weight-light color-black"
              >Click para copiar dirección</a
            >
          </div>
        </div>
      </div>
      <article class="c-grid--services__item pb-2">
        <div class="calculate-font text-center c-content--line-half">Ó</div>
      </article>
      <div class="c-content--calculate-service">
        <h2>Calcular servicios</h2>
        <article class="c-grid--services__item">
          <div
            id="btnOpenModalCalculatorCompraTrae"
            class="card c-card c-card--service-box d-flex p-1"
          >
            <div class="card-image">
              <div class="card-title color-text-black">
                <span class="card-sub-title color-text-black">calcular</span>
                <span class="card-option">Envío Compra & Trae</span>
              </div>
            </div>
            <img
              src="~@/assets/img/service-calculate-1.svg"
              class="translatex"
            />
          </div>
        </article>
      </div>
      <div class="pt-2 pb-4">
        <div class="m-0 d-flex justify-content-center">
          <a
            id="btnOpenModalCTRestriction"
            class="c-button c-button--five c-button--mobile-small waves-effect restriccion"
          >
            Ver restricciones
            <img src="~@/assets/img/icon/icon-danger.svg" alt />
          </a>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import M from "materialize-css";

export default {
  name: "Step1-Content",
  computed: {
    user() {
      return this.$store.getters.USER;
    },
    message() {
      return `Sharf ${this.user.fullName} DORAL: 2227 NW 79Th Ave. Doral,Fl.33198. Tel: +1 305-599-0115`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // console.log("open");
      // this.openModal("modalInfoAlmacen");
    });
  },
  methods: {
    closeModal() {
      const modalCalculator = document.querySelector("#modalInfoAlmacen");
      const instance2 = M.Modal.getInstance(modalCalculator);
      instance2.close();
    },
  },
};
</script>

<style></style>
