<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <a
      class="c-button c-button--four c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="callValidation"
    >
      <div v-if="coupon">
        <span>Pagar y enviar </span>
        <del>{{ ` S/ ${fare.SubTotal.toFixed(2)}` }}</del>
        <span style="font-weight: bold">{{
          ` S/ ${fare.totalFare.toFixed(2)}`
        }}</span>
      </div>
      <div v-else>
        {{
          fare !== null
            ? fare == 0
              ? "Enviar gratis"
              : `Pagar y enviar S/ ${fare.totalFare.toFixed(2)}`
            : "Error al calcular"
        }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Step3Footer",
  methods: {
    callValidation() {
      this.$eventBus.$emit("validateExpressStep3");
    },
  },
  computed: {
    fare() {
      return this.$store.getters.EXPRESS_FARE;
    },
    coupon() {
      return this.$store.getters.PROGRAMMED_COUPON;
    },
  },
};
</script>

<style></style>
