<template>
  <div class="container">
    <img
      v-if="showClose"
      @click="closeModal"
      class="close-btn-modal"
      src="~@/assets/img/icon/icon-closex.svg"
      style="position: absolute; top: 20px; right: 20px; cursor: pointer"
    />
    <img
      v-if="showResult"
      @click="showResult = false"
      src="~@/assets/img/icon/icon-left-arrow.svg"
      style="
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(74deg)
          brightness(105%) contrast(102%);
      "
    />
    <div class="header">
      <span style="">{{
        showResult ? "detalle de cotización" : "cotizador"
      }}</span>
    </div>
    <div class="content pt-2">
      <span>Paquete</span>
      <div class="c-content--range d-flex justify-content-between w-100 pb-4">
        <div class="w-60">
          <label required data-pristine-required-message="My custom message"
            >Peso (kg)</label
          >
          <p class="range-field mr-1">
            <input
              v-model="weight"
              type="range"
              min="1"
              max="90"
              class="m-0"
              :disabled="showResult"
            />
          </p>
        </div>
        <div class="content w-40 d-flex justify-content-center">
          <div
            class="c-input input-field w-full"
            :class="{ 'c-input-error': !isValidWeigth }"
          >
            <input
              type="number"
              min="0"
              max="90"
              v-model="weight"
              :disabled="showResult"
            />
            <label for class="font-weight-light active">kg</label>
          </div>
          <span
            v-if="!isValidWeigth"
            style="color: red; font-weight: bold; font-size: 12px"
            >Máximo permitido: 90Kg</span
          >
        </div>
      </div>
      <div class="pb-4">
        <div
          class="c-input input-field"
          :class="{ 'c-input-error': !isValidValue }"
        >
          <input
            type="number"
            @keypress="onlyNumbers"
            v-model="value"
            placeholder="0"
            max="2000"
            :disabled="showResult"
          />
          <label for class="active">Valor del Producto ($USD)</label>
        </div>
        <span
          v-if="!isValidValue"
          style="color: red; font-weight: bold; font-size: 12px"
          >Máximo permitido: 2000 $USD</span
        >
      </div>
      <div class="c-switch switch pb-4">
        <label class="row">
          <div class="col s4 p-0 d-flex align-items-center">
            <input
              type="checkbox"
              v-model="isProvince"
              :disabled="showResult"
            />
            <span class="lever m-0"></span>
            <div class="c-text-medium font-weight-bold ml-1">Provincia</div>
          </div>
        </label>
      </div>
      <div class="pb-6">
        <div class="col s12" v-if="!showResult">
          <button
            v-on:click="handleSubmit()"
            class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
            :disabled="!isValidForm"
          >
            Calcular
          </button>
        </div>
        <div class="col s12" v-else>
          <div class="row-detail">
            <span style="font-weight: bolder"
              >Estimado de aranceles a la Aduana</span
            >
            <span style="font-weight: bolder">$USD</span>
          </div>
          <div class="row-detail">
            <span>Ad-valorem</span>
            <span>$ {{ resQuote.addvalorem }}</span>
          </div>
          <div class="row-detail">
            <span>IGV</span>
            <span>$ {{ resQuote.arancelesIGV }}</span>
          </div>
          <div class="row-detail">
            <span>IPM</span>
            <span>$ {{ resQuote.IPM }}</span>
          </div>
          <div class="row-detail" style="margin-top: 20px">
            <span style="font-weight: bolder">Estimado de pagos a Sharf</span>
            <span style="font-weight: bolder">$USD</span>
          </div>
          <div class="row-detail">
            <span>Servicio*</span>
            <span>$ {{ resQuote.servicioSinIGV }}</span>
          </div>
          <div class="row-detail">
            <span>Distribución</span>
            <span>$ {{ resQuote.distribucionSinIGV }}</span>
          </div>
          <div class="row-detail">
            <span>Subtotal operación grabada</span>
            <span>$ {{ resQuote.subTotalOperacionGravada }}</span>
          </div>
          <div class="row-detail">
            <span>IGV (18%)</span>
            <span>$ {{ resQuote.subTotalOperacionIGV }}</span>
          </div>
          <div class="row-detail" style="margin-top: 20px">
            <span style="font-weight: bolder"
              >Total estimado de aranceles a la Aduana</span
            >
            <span style="font-weight: bolder">$ {{ resQuote.aranceles }}</span>
          </div>
          <div class="row-detail">
            <span style="font-weight: bolder"
              >Total estimado de pagos a Sharf</span
            >
            <span style="font-weight: bolder"
              >$ {{ resQuote.totalEstimadoPagoScharff }}</span
            >
          </div>
          <div class="row-detail">
            <span style="font-weight: bolder; font-family: 'Manrope'"
              >Pago total por el servicio</span
            >
            <span style="font-weight: bolder">$ {{ resQuote.total }}</span>
          </div>
        </div>
      </div>
      <div class="pb-4" style="font-size: 0.7rem">
        <span v-if="showResult">
          * Servicio: Consolidación en almacenes Miami, transporte internacional
          de USA a Perú, proceso de declaración y liberación ante la Aduana y
          almacenaje.
        </span>
        <br v-if="showResult" />
        <br v-if="showResult" />
        <span
          >Las tarifas son referenciales y su valor será más cercano al costo
          real entre más específica sea la información que ingreses en el
          formulario. (Precios válidos solo para los trayectos de Lima a
          provincias y de Lima a Lima)</span
        >
        <br />
        <br />
        <span
          >Si desea más información, puedes revisar nuestros
          <a
            target="popup"
            href="https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf"
            style="color: #ff565d"
            >Términos y condiciones</a
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

export default {
  name: "QuoterCmp",
  props: {
    formId: String,
    showClose: {
      default: true,
    },
  },
  data() {
    return {
      showResult: false,
      weight: 1,
      value: null,
      isProvince: false,

      error: false,
    };
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.value = this.value.replace(/[^0-9]/g, "");
      });
    },
  },
  computed: {
    isValidWeigth() {
      return this.weight <= 90;
    },
    isValidValue() {
      return this.value <= 2000;
    },
    isValidForm() {
      return this.isValidWeigth && this.isValidValue;
    },
  },
  methods: {
    handleSubmit() {
      const isValid = this.validateInputs();

      if (!isValid) {
        return;
      }

      this.showLoading("Calculando...");

      const requestData = {
        weight: this.weight,
        value: this.value,
        provinceId: this.isProvince ? 0 : 128,
      };

      this.$store
        .dispatch("COMPRATRAE_CALCULATE_FARE", requestData)
        .then((response) => {
          this.$swal.close();
          this.resQuote = response;
          this.showResult = true;
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    validateInputs() {
      if (this.weight === 0) {
        this.showError("Debe ingresar un peso");
        return false;
      }

      if (this.weight > 90) {
        this.showError("El peso no puede ser mayor a 90 kg");
        return false;
      }

      if (!this.value || this.value === 0) {
        this.showError("Debe ingresar el valor del producto");
        return false;
      }

      return true;
    },

    closeModal() {
      const modalCalculatorBuyAndBring = document.querySelector(
        "#modalCalculatorBuyAndBring"
      );
      const modalCalculator = document.querySelector("#modalCalculator");
      if (modalCalculatorBuyAndBring) {
        const instance = M.Modal.getInstance(modalCalculatorBuyAndBring);
        instance.close();
      }
      if (modalCalculator) {
        const instance2 = M.Modal.getInstance(modalCalculator);
        instance2.close();
      }
    },

    reset() {
      this.weight = 0;
      this.value = 0;
      this.quote = {
        isProvince: false,
      };
      this.aranceles = 0;
      this.descuento = 0;
      this.distribucion = 0;
      this.flete = 0;
      this.igv = 0;
      this.impuestos = null;
      this.margen = 0;
      this.precioSinIGV = 0;
      this.servicio = 0;
      this.shipping = null;
      this.total = 0;
      this.totalUSA = 0;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-height: 90vh;
  overflow-x: auto;
  position: relative;
}
.header {
  height: 120px;
  width: 100%;
  background-color: var(--g-color-first);
  border-radius: 0% 0% 80px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    font-size: 2.5rem;
    font-weight: bolder;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}

.row-detail {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px #cfcfcf;
  margin-top: 10px;
  padding-bottom: 5px;
}
</style>
