<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <h2 class="c-text-left">Datos del importador</h2>
      <article class="c-grid--services__item c-section--dateContent p-0">
        <div class="c-modal--directions-body p-0">
          <div
            class="c-input input-field"
            v-if="selectedDocumentType.Id !== 'ruc'"
          >
            <input type="text" v-model="lastName" />
            <label for class="active">{{ "Apellidos Completos" }}</label>
          </div>
          <div class="c-input input-field" v-else>
            <input type="text" v-model="razonSocial" />
            <label for class="active">{{ "Razon Social" }}</label>
          </div>
        </div>
        <div
          v-if="selectedDocumentType.Id !== 'ruc'"
          class="c-modal--directions-body p-0"
        >
          <div class="c-input input-field">
            <input type="text" v-model="firstName" />
            <label for class="active">Nombres Completos</label>
          </div>
        </div>
        <div class="c-modal--directions-body p-0">
          <div class="c-input input-field">
            <input
              type="text"
              v-model="consigneePhone"
              @keypress="onlyNumbers"
              @paste="removeLettersOnPaste2($event, 'consigneePhone')"
              maxlength="9"
              minlength="9"
            />
            <label for class="active">Número de celular</label>
          </div>
        </div>
        <div class="c-select input-field curso-pointer">
          <select
            v-model="selectedDocumentTypeId"
            v-on:change="setSelectedDocumentType"
          >
            <option value disabled="disabled">Seleccionar</option>
            <option
              v-for="docType in documentTypes"
              v-bind:value="docType.Id"
              v-bind:key="docType.Id"
            >
              {{ docType.Text }}
            </option>
          </select>

          <label for class="select-label">Tipo de documento</label>
        </div>
        <div v-show="selectedDocumentType.Id === 'ce'">
          <br />
          <h3 class="c-text-left">Subir documento de extranjería: ANVERSO</h3>
          <upload-zone
            @getFiles="setPassportImagesFront"
            :allowMultiple="false"
          />
          <div class="c-text-format">Adjunte imagenes en JPG o PNG.</div>
          <br />
          <h3 class="c-text-left">Subir documento de extranjería: REVERSO</h3>
          <upload-zone
            @getFiles="setPassportImagesBack"
            :allowMultiple="false"
          />
          <div class="c-text-format">Adjunte imagenes en JPG o PNG.</div>
          <br />
        </div>
        <div
          class="c-modal--directions-body p-0"
          v-if="selectedDocumentType.Id == 'dni'"
        >
          <div class="c-input input-field">
            <input type="text" v-model="dniNumber" @keypress="onlyNumbers" />
            <label for class="active">Nro. de Documento</label>
          </div>
        </div>
        <div
          class="c-modal--directions-body p-0"
          v-else-if="
            selectedDocumentType.Id == 'ruc' ||
            selectedDocumentType.Id == 'ruc-nat'
          "
        >
          <div class="c-input input-field">
            <input type="text" v-model="rucNumber" @keypress="onlyNumbers" />
            <label for class="active">Nro. de Documento</label>
          </div>
        </div>
        <div class="c-modal--directions-body p-0" v-else>
          <div class="c-input input-field">
            <input
              type="text"
              v-model="consigneeDocumentNumber"
              @keypress="onlyNumbers"
            />
            <label for class="active">Nro. de Documento</label>
          </div>
        </div>
      </article>
    </div>
  </article>
</template>

<script>
import M from "materialize-css";
import UploadZone from "../../General/UploadZone.vue";

export default {
  data() {
    return {
      documentTypes: [],
      lastName: "",
      razonSocial: "",
      firstName: "",
      dniNumber: "",
      rucNumber: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.USER;
    },
    selectedDocumentTypeId: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_SELECTED_DOCUMENT_TYPE.Id;
      },
      set() {},
    },
    passportImageFront: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_PASSPORT_IMAGE_FRONT;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_PASSPORT_IMAGE_FRONT", value);
      },
    },
    passportImageBack: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_PASSPORT_IMAGE_BACK;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_PASSPORT_IMAGE_BACK", value);
      },
    },
    consigneeLastName: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_CONSIGNEE_LASTNAME;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_CONSIGNEE_LASTNAME", value);
      },
    },
    consigneeName: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_CONSIGNEE_NAME;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_CONSIGNEE_NAME", value);
      },
    },
    consigneePhone: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_CONSIGNEE_PHONE;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_CONSIGNEE_PHONE", value);
      },
    },
    consigneeDocumentNumber: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_NUMBER;
      },
      set(value) {
        this.$store.dispatch(
          "COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_NUMBER",
          value
        );
      },
    },
    selectedDocumentType: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_SELECTED_DOCUMENT_TYPE;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_SELECTED_DOCUMENT_TYPE", value);
      },
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("EXPRESS_ORDER_CONTACT_CLEAN");
    });

    this.$eventBus.$on("validateCompraTraeStep3", () => {
      this.validateStep3();
    });
    await this.getDocumentTypeList();
    this.initData();
  },
  methods: {
    initData() {
      this.lastName = `${this.user.firstLastName} ${this.user.secondLastName}`;
      this.firstName = this.user.name;
      this.razonSocial = this.user.razonSocial;
      this.consigneePhone = this.user.celular;
      this.dniNumber = this.user.dni;
      this.rucNumber = this.user.ruc;
    },
    validateStep3() {
      if (this.selectedDocumentTypeId === "dni") {
        this.consigneeLastName = this.lastName;
        this.consigneeName = this.firstName;
        this.consigneeDocumentNumber = this.dniNumber;
      } else if (this.selectedDocumentTypeId === "ruc") {
        this.consigneeLastName = this.razonSocial;
        this.consigneeName = "";
        this.consigneeDocumentNumber = this.rucNumber;
      } else if (this.selectedDocumentTypeId === "ruc-nat") {
        this.consigneeLastName = this.lastName;
        this.consigneeName = this.firstName;
        this.consigneeDocumentNumber = this.rucNumber;
      } else {
        this.consigneeLastName = this.lastName;
        this.consigneeName = this.firstName;
      }
      if (this.consigneeLastName === "") {
        if (this.selectedDocumentType.Id !== "ruc") {
          this.showError("Ingrese los apellidos del consignatario");
        } else {
          this.showError("Ingrese la Razón Social");
        }
        return false;
      }

      if (this.consigneeName === "" && this.selectedDocumentType.Id !== "ruc") {
        this.showError("Ingrese los nombres del consignatario");
        return false;
      }

      if (this.consigneePhone === "") {
        this.showError("Ingrese el número de celular del consignatario");
        return false;
      }
      if (
        this.selectedDocumentType.Id === "" ||
        this.selectedDocumentType.Id === null
      ) {
        this.showError("Seleccione el tipo de documento");
        return false;
      }

      if (
        this.consigneeDocumentNumber.length !== this.selectedDocumentType.Size
      ) {
        if (this.selectedDocumentType.IsNumber) {
          this.showError(
            `El ${this.selectedDocumentType.Text} debe tener ${this.selectedDocumentType.Size} dígitos`
          );
        } else {
          this.showError(
            `El ${this.selectedDocumentType.Text} debe tener ${this.selectedDocumentType.Size} caracteres`
          );
        }
        return false;
      }

      if (
        this.selectedDocumentType.IsNumber &&
        !this.consigneeDocumentNumber.match(/^[0-9]+$/)
      ) {
        this.showError(
          `El ${this.selectedDocumentType.Text} debe ser numérico`
        );
        return false;
      }

      if (this.selectedDocumentType.Id === "ce") {
        if (this.passportImageFront.length === 0) {
          this.showError(
            "Debe adjuntar la imagen del documento de extranjería ANVERSO"
          );
          return false;
        }
        if (this.passportImageBack.length === 0) {
          this.showError(
            "Debe adjuntar la imagen del documento de extranjería REVERSO"
          );
          return false;
        }
      }

      this.$store.dispatch("COMPRATRAE_PROGRESS_NAV_STEP", 4);
      return true;
    },
    onSubmit() {
      this.$emit("submit", this.order);
    },
    setPassportImagesFront(files) {
      this.$store.dispatch("COMPRATRAE_ORDER_PASSPORT_IMAGE_FRONT", files);

      // this.passportImageFront = files;
    },
    setPassportImagesBack(files) {
      this.$store.dispatch("COMPRATRAE_ORDER_PASSPORT_IMAGE_BACK", files);

      // this.passportImageBack = files;
    },
    getDocumentTypeList() {
      this.$store
        .dispatch("GET_DOCUMENT_TYPES")
        .then((response) => {
          const data = response;

          data.forEach((item) => {
            this.documentTypes.push(item);
          });

          this.$nextTick(() => {
            this.initSelect();
          });
          this.selectedDocumentType = this.user.ruc
            ? this.documentTypes.find((item) => item.Id === "ruc")
            : this.documentTypes.find((item) => item.Id === "dni");
          this.$store.dispatch(
            "COMPRATRAE_ORDER_SELECTED_DOCUMENT_TYPE",
            this.selectedDocumentType
          );
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    initSelect() {
      // eslint-disable-next-line camelcase
      const elems_select = document.querySelectorAll(".c-select select");
      M.FormSelect.init(elems_select);
    },
    setSelectedDocumentType(val) {
      const selectedDocumentType = this.documentTypes.find(
        (item) => item.Id === val.target.value
      );

      this.$store.dispatch(
        "COMPRATRAE_ORDER_SELECTED_DOCUMENT_TYPE",
        selectedDocumentType
      );
    },
  },
  components: {
    UploadZone,
  },
};
</script>

<style></style>
