<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <div class="c-content--spacing-block">
        <h3>1) Paquete</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div class="collection-item collection-item--title">
            Categoria: <span class="info-light"> {{ categoryName }} </span>
          </div>
          <div class="collection-item">
            Valor del producto S./ {{ productValue.toFixed(2) }}
          </div>
        </div>
      </div>
      <div class="c-section--point">
        <div class="c-section--point-pick" v-if="origin && contactOrigin">
          <ul>
            <li class="info-black">Lugar de recojo</li>
            <li class="info-light">{{ origin.description }}</li>
            <li class="info-black">Contacto</li>
            <li class="info-light">
              # {{ contactOrigin.phone }} - {{ contactOrigin.name }}
            </li>
          </ul>
        </div>
        <div
          :class="[
            index === destinations.length - 1
              ? 'c-section--point-delivery'
              : 'c-section--point-delivery-multiple',
          ]"
          v-for="(destination, index) in destinations"
          :key="destination.index"
        >
          <ul>
            <li class="info-black">Dirección de entrega</li>
            <li class="info-light">{{ destination.description }}</li>
            <li class="info-black">Contacto</li>
            <li
              class="info-light"
              v-if="contactDestination.length > 0 && contactDestination[index]"
            >
              # {{ contactDestination[index].phone }} -
              {{ contactDestination[index].name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="c-content--spacing-block">
        <h3>3) Tipo de comprobante</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
          >
            <a
              href="javascript:void(0)"
              id="btnVoucher"
              @click="openTypeVoucher"
              class="collection-item--icon collection-item--icon--ticket"
              >{{ !voucher ? "Comprobante de Pago" : voucher.typeVoucher }}
            </a>
          </div>
        </div>
      </div>
      <div class="c-content--spacing-block">
        <h3>4) Método de Pago</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
          >
            <a
              href="javascript:void(0)"
              id="openModalTypeCard"
              @click="openTypeCard"
              class="collection-item--icon collection-item--icon--card"
              :class="[card ? card.cardBrand.toLowerCase() : '']"
            >
              {{
                !card
                  ? "Método de pago"
                  : card.cardBrand + " " + card.cardNumber
              }}
            </a>
          </div>
        </div>
      </div>
      <div class="c-content--spacing-block">
        <h3>6) ¿Tienes cupón de descuento?</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
            style="display: flex; align-items: center"
          >
            <span
              style="
                margin-right: 10px;
                color: white;
                border: solid 1px black;
                border-radius: 100%;
                width: 33px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: black;
                cursor: pointer;
              "
              @click="deleteCoupon()"
              v-if="coupon"
              >X</span
            >
            <a
              href="javascript:void(0)"
              id="openModalCoupon"
              @click="openCoupon"
              class="collection-item--icon collection-item--icon--dsct"
            >
              <p
                style="
                  text-overflow: ellipsis;
                  max-width: 200px;
                  overflow: hidden;
                  white-space: nowrap;
                "
              >
                {{ coupon ? coupon.code : "Cupon" }}
              </p>
              <b v-if="coupon">{{ `(${coupon.descriptiveValue})` }}</b>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="c-content--spacing-block">
        <h3>7) Propina</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
          >
            <a
              href="javascript:void(0)"
              id="openModalTip"
              @click="openTip"
              class="collection-item--icon collection-item--icon--tip"
            >
              Propina de S/ {{ tip.toFixed(2) }}</a
            >
          </div>
        </div>
      </div> -->
    </div>
  </article>
</template>

<script>
import * as Sentry from "@sentry/vue";
import M from "materialize-css";

export default {
  name: "Step3Content",
  mounted() {
    this.fetchCards();
    this.$eventBus.$on("validateExpressStep3", () => {
      this.validateExpressStep3();
    });
    this.$eventBus.$on("createOrder", () => {
      this.createOrder();
    });
  },
  methods: {
    async fetchCards() {
      this.showLoading();
      await this.$store
        .dispatch("GET_CARDS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const cards = success.data.result;
            this.$store.dispatch("GENERAL_SET_CARDS", cards);
            if (cards.length > 0) {
              this.$store.dispatch("EXPRESS_CARD", cards[0]);
            }
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    openTypeVoucher() {
      this.openModal("modalTypeVoucher");
    },
    openTypeCard() {
      this.openModal("modalTypeCard");
    },
    openTip() {
      this.openModal("modalTip");
    },
    openCoupon() {
      this.openModal("modalCoupon");
    },
    async deleteCoupon() {
      this.$store.dispatch("EXPRESS_COUPON", null);
      this.showLoading();
      await this.$store.dispatch("EXPRESS_CALCULATE_FARE");
      this.$swal.close();
    },
    async validateExpressStep3() {
      if (!this.voucher) {
        this.showError("Por favor ingrese los datos del comprobante de pago.");
        return false;
      }

      if (this.fare.totalFare !== 0 && !this.card) {
        this.showError("Por favor ingrese un metodo de pago.");
        return false;
      }
      this.showLoading("Procesando...");

      await this.createOrder();      
      return true;
    },
    async createOrder() {
      const modalCalculator = document.querySelector("#modalDetailQuote");
      const instance2 = M.Modal.getInstance(modalCalculator);
      instance2.close();
      this.showLoading("Procesando...");
      await this.$store
        .dispatch("EXPRESS_CREATE_FARE")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            this.$store.dispatch("SET_PAYMENT_RESULT", success.data.result);
            this.$store.dispatch("EXPRESS_PROGRESS_NAV_STEP", 4);
            this.$store.dispatch("STYLE_HOME_DIV");
            this.$store.dispatch("STYLE_EXPRESS_STEP_DIV_W_BG");
            this.$store.dispatch("STYLE_MAP_SAIO", false);
            return true;
          }
          this.showError(success.data.message);
          return false;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              ErrorType: "Prioritize",
            },
          });
          const catchMsg = this.catchStandardErrorMsg(error);
          this.$swal.close();
          this.showError(catchMsg);
          return false;
        })
        .finally(() => {});
    },
  },
  computed: {
    productValue() {
      return this.$store.getters.EXPRESS_PRODUCT_VALUE;
    },
    categoryId() {
      return this.$store.getters.EXPRESS_CATEGORY_ID;
    },
    categoryName() {
      return this.$store.getters.EXPRESS_CATEGORY_NAME;
    },
    voucher() {
      return this.$store.getters.EXPRESS_VOUCHER;
    },
    card() {
      return this.$store.getters.EXPRESS_CARD;
    },
    origin() {
      return this.$store.getters.EXPRESS_ORIGIN;
    },
    destinations() {
      return this.$store.getters.EXPRESS_DESTINATIONS;
    },
    contactOrigin() {
      return this.$store.getters.EXPRESS_ORIGIN_CONTACT;
    },
    contactDestination() {
      return this.$store.getters.EXPRESS_DESTINATION_CONTACT;
    },
    tip() {
      return this.$store.getters.EXPRESS_TIP;
    },
    coupon() {
      return this.$store.getters.EXPRESS_COUPON;
    },
    user() {
      return this.$store.getters.USER;
    },
    cards() {
      return this.$store.getters.GENERAL_CARDS;
    },
    fare() {
      return this.$store.getters.EXPRESS_FARE;
    },
  },
};
</script>

<style></style>
