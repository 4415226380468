<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--img center-align">
      <div class="c-content--modalBannerCurved pt-1 pb-1">
        <div class="c-content--title-top">Confirmación exitosa</div>
      </div>
      <div class="c-section--bannerCircle colorSecundaryBanner"></div>
      <img
        class="pt-2"
        src="~@/assets/img/service-cart-check.png"
        alt=""
        width="280"
        height="auto"
        load="lazy"
      />
    </div>
    <div
      class="c-content--app_container"
      v-if="origin && contactOrigin && categoryName && productValue"
    >
      <div class="c-content--spacing-block">
        <div class="c-section--content-article">
          <article class="c-grid--services__item">
            <div
              class="c-section--content-date-designation font-weight-bold pr-0 pl-0 pb-4 text-center justify-content-center"
            >
              Muy pronto estaremos llegando
            </div>
            <div
              v-if="payment !== null"
              style="
                padding-left: 2em;
                padding-bottom: 1em;
                line-height: 1.67;
                background: white;
                border: 1px solid #e0e0e0;
                border-radius: 15px;
                padding-top: 1em;
                margin-bottom: 1.5em;
              "
            >
              <b>nro. de pedido:</b> {{ payment.purchaseNumber }} <br />
              <b>nombre y apellido del cliente:</b> {{ payment.firstName }}
              {{ payment.lastName }} <br />
              <b>nro. de tarjeta:</b> {{ payment.cardNumber }} <br />
              <b>marca de la tarjeta:</b> {{ payment.cardBrand }} <br />
              <b>fecha y hora del pedido:</b> {{ payment.date }} <br />
              <b>tipo de moneda:</b> {{ payment.currency }} <br />
              <b>términos y condiciones:</b>
              <a
                href="https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf"
                target="popup"
                onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf','popup','width=600,height=600'); return false;"
                style="color: rgb(255, 0, 0); text-decoration: underline"
                >click aquí</a
              >
            </div>
            <div class="c-section--content-point pb-2">
              <div class="c-section--point-pick">
                <ul>
                  <li class="info-black">Lugar de recojo</li>
                  <li class="info-light">{{ origin.description }}</li>
                  <li class="info-black">Contacto</li>
                  <li class="info-light">
                    # {{ contactOrigin.phone }} - {{ contactOrigin.name }}
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
        <h3>Producto a recoger:</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div class="collection-item">
            <div class="collection-item__separate">
              <div class="c-texto font-weight-bold">{{ categoryName }}</div>
              <div class="c-texto font-weight-light">
                S/ {{ productValue.toFixed(2) }}
              </div>
            </div>
          </div>
          <div class="collection-item" v-if="false">
            <div class="collection-item__united">
              <div class="c-texto font-weight-bold">Dimensiones</div>
              <div class="c-texto font-weight-light">
                Alto: 20cm , Ancho:30cm, Largo: 40cm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

export default {
  name: "OrderConfirmationContent",
  computed: {
    origin() {
      return this.$store.getters.EXPRESS_ORIGIN;
    },
    contactOrigin() {
      return this.$store.getters.EXPRESS_ORIGIN_CONTACT;
    },
    productValue() {
      return this.$store.getters.EXPRESS_PRODUCT_VALUE;
    },
    categoryName() {
      return this.$store.getters.EXPRESS_CATEGORY_NAME;
    },
    payment() {
      return this.$store.getters.PAYMENT_RESULT;
    },
  },
};
</script>

<style></style>
