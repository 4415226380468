<template>
  <article class="c-content--sendPoint c-scrollbar" id="c-search-inputs">
    <div class="c-content--app_container">
      <h2>Información del paquete</h2>
      <div class="c-box--img-floating c-text-center">
        <img src="~@/assets/img/service-paquete.svg" alt="" />
      </div>
      <div class="c-select input-field curso-pointer">
        <select @change="onChangeSelect($event)" v-model="selectedValue">
          <option value disabled="disabled">Seleccionar</option>
          <option
            v-for="category in categories"
            v-bind:value="category.id"
            v-bind:key="category.id"
          >
            {{ category.text }}
          </option>
        </select>
        <label for="" class="select-label">Categoria</label>
      </div>
      <div class="c-input input-field curso-pointer">
        <input
          class="curso-pointer"
          @keypress="onlyNumbers"
          @paste="removeLettersOnPaste2($event, 'productValue')"
          @change="onChangeProductValue($event)"
        />
        <label for class="active">Valor referencial del producto</label>
      </div>
      <br />
      <div class="c-text-center">
        <button
          class="c-button c-button--five c-button--mobile-small waves-effect restriccion"
          id="btnOpenModalRestriction"
        >
          Ver restricciones<img
            src="~@/assets/img/icon/icon-danger.svg"
            alt=""
          />
        </button>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "Step2-Content",
  data() {
    return {
      categories: [],
      selectedValue: 0,
    };
  },
  mounted() {
    this.$eventBus.$on("validateExpressStep2", () => {
      this.validateExpressStep2();
    });

    this.$nextTick(() => {
      this.fetchCategories();
    });
  },
  methods: {
    onChangeSelect(event) {
      this.categoryId = parseInt(event.target.value, 10);
    },
    onChangeProductValue(event) {
      this.productValue = parseFloat(event.target.value);
    },
    fetchCategories() {
      this.$store
        .dispatch("COMPRATRAE_FETCH_CATEGORIES")
        .then((response) => {
          const data = response;

          data.forEach((item) => {
            this.categories.push(item);
          });

          this.$nextTick(() => {
            this.initSelects();
            this.$store.dispatch("EXPRESS_CATEGORIES", this.categories);
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: error,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        });
    },
    async validateExpressStep2() {
      if (!this.productValue || !this.productValue > 0) {
        this.showError(
          "Por favor ingrese un valor referencial del producto válido."
        );
        return false;
      }

      if (!this.categoryId || this.categoryId === 0) {
        this.showError("Por favor seleccione una categoria.");
        return false;
      }

      this.showLoading();
      await this.$store.dispatch("EXPRESS_CALCULATE_FARE");
      this.$store.dispatch("EXPRESS_PROGRESS_NAV_STEP", 3);
      this.$swal.close();
      return true;
    },
  },
  computed: {
    categoryId: {
      get() {
        return this.$store.getters.EXPRESS_CATEGORY_ID;
      },
      set(value) {
        this.$store.dispatch("EXPRESS_CATEGORY_ID", value);
      },
    },
    productValue: {
      get() {
        return this.$store.getters.EXPRESS_PRODUCT_VALUE;
      },
      set(value) {
        this.$store.dispatch("EXPRESS_PRODUCT_VALUE", value);
      },
    },
  },
};
</script>

<style></style>
