<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <a
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="callValidation"
      id="btnOpenModalSetAdress"
      >Finalizar</a
    >
  </div>
</template>

<script>
export default {
  name: "Step2-Footer",
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    callValidation() {
      this.$eventBus.$emit("validateExpressStep2");
    },
  },
};
</script>

<style></style>
