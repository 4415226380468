<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <button
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="next"
    >
      Continuar
    </button>
  </div>
</template>

<script>
export default {
  name: "Step1-Footer",
  methods: {
    next() {
      this.$store.dispatch("COMPRATRAE_PROGRESS_NAV_STEP", 2);
    },
  },
};
</script>

<style></style>
