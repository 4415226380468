<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <h2 class="c-text-left">Datos del paquete</h2>
      <article class="c-grid--services__item c-section--dateContent p-0">
        <div class="curso-pointer relative">
          <label
            style="
              position: absolute;
              top: -7px;
              left: 10px;
              z-index: 1;
              font-size: 14px;
              background: #fbfbff;
              width: auto;
              padding: 0.2em 1em;
              color: #000;
            "
            >Categoria del paquete</label
          >
          <v-select
            v-model="categoryIds"
            multiple
            label="text"
            :options="categories"
            :reduce="(category) => category.id"
          />
        </div>
        <span
          v-show="isRestrictedCategoriesSelected"
          class="restricted-categories-warning"
        >
          El producto que está importando es considerado MERCADERÍA RESTRINGIDA
          (
          <a
            href="https://saio.holascharff.com/Files/mercaderia-restringida.pdf"
            target="popup"
            onclick="window.open('https://saio.holascharff.com/Files/mercaderia-restringida.pdf','popup','width=600,height=600'); return false;"
            style="color: rgb(241, 85, 108)"
          >
            Haz click aqui para ver la
            <b>restricción</b>
          </a>
          ).
        </span>
        <div class="c-input input-field" v-show="isCategoryOtherSelected">
          <input type="text" v-model="otherCategoryText" />
          <label for class="active">Otro</label>
        </div>
        <div
          class="c-input input-field"
          :class="{ 'c-input-error': value > 2000 }"
        >
          <input
            type="number"
            max="2000"
            @keypress="onlyNumbers"
            @paste="removeLettersOnPaste2($event, 'value')"
            v-model="value"
          />
          <label for class="active">Valor del producto ($USD)</label>
        </div>
        <span
          v-if="value > 2000"
          style="color: red; font-weight: bold; font-size: 12px"
          >Máximo permitido: 2000 $USD</span
        >
      </article>
      <div class="pt-2 pb-2">
        <div class="m-0 d-flex justify-content-center">
          <a
            id="btnOpenModalCTRestriction"
            class="c-button c-button--five c-button--mobile-small waves-effect restriccion"
          >
            Ver restricciones
            <img src="~@/assets/img/icon/icon-danger.svg" alt />
          </a>
        </div>
      </div>

      <div
        class="c-content--box-shadow d-flex p-1 mt-2 mb-2 border-radius flex-wrap"
      >
        <div class="c-content--range d-flex justify-content-between p-2 w-100">
          <form action="#" class="w-60">
            <label for>Paquetes que van a llegar</label>
            <p class="range-field mr-1">
              <input
                type="range"
                v-model="quantity"
                min="0"
                max="4"
                class="m-0"
              />
              <span class="thumb">
                <span class="value"></span>
              </span>
            </p>
          </form>
          <div
            class="content w-40 d-flex justify-content-center align-items-end"
          >
            <div class="c-input input-field">
              <input type="number" v-model="quantity" max="4" />
              <label for class="font-weight-light active">Cantidad</label>
            </div>
          </div>
        </div>
        <div class="c-text-medium p-2">
          Puedes determinar el número de paquetes que llegarán según el número
          de trackings/órdenes que te envía tu ecommerce
        </div>
        <div class="c-content--range d-flex justify-content-between p-2 w-100">
          <form action="#" class="w-60">
            <label for>Peso (kg)</label>
            <p class="range-field mr-1">
              <input
                type="range"
                v-model="weight"
                min="1"
                max="90"
                class="m-0"
              />
              <span class="thumb">
                <span class="value"></span>
              </span>
            </p>
          </form>
          <div
            class="content w-40 d-flex justify-content-center"
            style="flex-direction: column"
          >
            <div
              class="c-input input-field w-full"
              :class="{ 'c-input-error': weight > 90 }"
            >
              <input type="text" v-model="weight" max="10" />
              <label for class="font-weight-light active">kg</label>
            </div>
            <span
              v-if="weight > 90"
              style="color: red; font-weight: bold; font-size: 12px"
              >Máximo permitido: 90Kg</span
            >
          </div>
        </div>
      </div>
      <br />
      <h2 class="c-text-left">Recibo del pago</h2>

      <upload-zone
        @getFiles="setVoucherImages"
        :filetypes="'application/pdf'"
      />

      <div class="c-text-format">Adjunte PDF.</div>
      <br />
      <br />
      <div class="c-content--list-date">
        <div class="c-text">
          Recuerda que tu comprobante de pago debe incluir lo siguiente:
        </div>
        <ul>
          <li>Nombre y/o logo de tienda</li>
          <li>Fecha de compra</li>
          <li>Descripción de la mercadería</li>
          <li>Cantidad de items</li>
          <li>Valor unitario y total de compra</li>
          <li>
            Nombre del cliente
            <br />(mínimo 1er nombre y 1er apellido)
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>
<script>
import UploadZone from "../../General/UploadZone.vue";

export default {
  name: "Step2-Contet-CyT",
  data() {
    return {
      categories: [],
    };
  },
  computed: {
    isCategoryOtherSelected() {
      return this.categoryIds.includes(23);
    },
    isRestrictedCategoriesSelected() {
      let isRestricted = false;
      this.categories
        .filter((x) => x.isRestringido === true)
        .forEach((x) => {
          if (this.categoryIds.includes(x.id)) {
            isRestricted = true;
          }
        });

      return isRestricted;
    },
    categoryIds: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_CATEGORY_IDS;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_CATEGORY_IDS", value);
      },
    },
    value: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_VALUE;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_VALUE", value);
      },
    },
    quantity: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_QUANTITY;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_QUANTITY", value);
      },
    },
    weight: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_WEIGHT;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_WEIGHT", value);
      },
    },
    voucherImages: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_VOUCHER_IMAGES;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_VOUCHER_IMAGES", value);
      },
    },
    otherCategoryText: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_OTHER_CATEGORY_TEXT;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_OTHER_CATEGORY_TEXT", value);
      },
    },
  },
  components: {
    UploadZone,
  },
  mounted() {
    this.fetchCategories();
    this.$eventBus.$on("validateCompraTraeStep2", () => {
      this.validateStep2();
    });
  },
  watch: {
    quantity() {
      if (this.quantity > 4) {
        this.quantity = "4";
      }
    },
  },
  methods: {
    fetchCategories() {
      this.$store
        .dispatch("COMPRATRAE_FETCH_CATEGORIES")
        .then((response) => {
          const data = response;

          data.forEach((item) => {
            this.categories.push(item);
          });

          this.$store.dispatch("COMPRATRAE_CATEGORIES", this.categories);

          this.$nextTick(() => {
            this.initSelect();
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: error,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        });
    },
    initSelect() {
      // eslint-disable-next-line camelcase
      const elems_select = document.querySelectorAll(".c-select select");
      // eslint-disable-next-line no-undef
      M.FormSelect.init(elems_select);
    },
    setVoucherImages(files) {
      this.voucherImages = files;
    },
    validateStep2() {
      if (this.categoryIds.length === 0) {
        this.showError("Debes seleccionar al menos una categoría");
        return false;
      }
      if (this.value === 0 || this.value === "") {
        this.showError("Debes ingresar el valor del producto");
        return false;
      }
      if (this.value > 2000) {
        this.showError("El valor del producto no puede ser mayor a $2.000");
        return false;
      }
      if (
        this.quantity === 0 ||
        this.quantity === "" ||
        this.quantity === "0"
      ) {
        this.showError(
          "Debes seleccionar la cantidad de paquetes que llegarán"
        );
        return false;
      }
      if (this.quantity > 4) {
        this.showError("La cantidad de paquetes no puede ser mayor a 4");
        return false;
      }
      if (this.weight === 0 || this.weight === "0" || this.weight === "") {
        this.showError("Debes seleccionar el peso de los paquetes");
        return false;
      }
      if (this.weight > 90) {
        this.showError("El peso de los paquetes no puede ser mayor a 90kg");
        return false;
      }
      if (this.voucherImages.length === 0) {
        this.showError("Debes adjuntar al menos un comprobante de pago");
        return false;
      }
      if (this.isCategoryOtherSelected) {
        if (this.otherCategoryText === "") {
          this.showError("Debes ingresar una descripción de la categoría");
          return false;
        }
      }

      this.$store.dispatch("COMPRATRAE_PROGRESS_NAV_STEP", 3);
      return true;
    },
  },
};
</script>
<style scoped>
.restricted-categories-warning {
  color: #f1556c;
  margin-top: 0px;
  position: relative;
  font-size: 12px;
  padding-left: 12px;
  display: block;
}
</style>

<style>
.vs__dropdown-toggle .vs__selected-options input {
  border-bottom: none !important;
  height: 30px;
}
.vs__dropdown-toggle {
  background: none !important;
  padding-top: 15px;
}
.vs__actions {
  cursor: pointer;
}
</style>
