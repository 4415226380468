<template>
  <div class='choose_option_container' @click='handleClick' :class="{ 'isSelectable': isSelectable }">
    <div style='display: flex; gap: 16px; align-items: center'>
      <slot v-if="$slots['icon']" name='icon'></slot>
      <slot v-if="$slots['content']" name='content'></slot>
    </div>
    <div v-if="$slots['right-icon']">
      <slot name='right-icon'></slot>
    </div>
    <div v-else>
      <Icon v-if='isSelectable' name='Arrow-Right-2' />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    isSelectable: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  }
}
</script>

<style lang='scss' scoped>
.choose_option_container {
  overflow: hidden;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  padding: 16px;

  width: 100%;

  background: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 12px;

  transition: .3s;

  &.isSelectable { cursor: pointer }

  &.isSelectable:hover {
    border: 1px solid transparent;
    background: rgba(0, 0, 0, 0.08);
  }
  &.isSelectable:active {
    background: rgba(0, 0, 0, 0.20);
  }
}
</style>