<template>
  <article class="c-content--sendPoint c-scrollbar" id="c-search-inputs">
    <div class="c-content--app_container">
      <h2>Importante</h2>
      <p>
        *Puedes realizar
        <strong style="font-weight: bold"
          >pedidos hasta las 05:00 p.m, caso contrario pasará al día
          siguiente.</strong
        >
      </p>
      <p>
        *Se incluirá un
        <strong style="font-weight: bold"
          >cobro ad. de S/0.25 por min, o fracción después de los 5 min.</strong
        >
        de espera en el recojo o entrega del pedido. El tiempo de espera máx.
        desde la llegada del Sharf es de 15 min.
      </p>
      <p>
        P.D.: La fracción que se considera como minuto adicional es a partir de
        10 seg.
      </p>
      <h2>Lugar de recojo</h2>
      <div class="div">
        <div class="c-input input-field curso-pointer">
          <vue-google-autocomplete
            ref="originAddress"
            id="c-focus-open-modal-search-direction"
            classname="curso-pointer"
            placeholder=""
            v-on:placechanged="getOriginAddressData"
            country="pe"
            v-on:focus="
              isOriginFocused = true;
              isDestinationFocused = false;
            "
          ></vue-google-autocomplete>

          <label for="">Dirección</label>
          <div
            id="c-icon-favorite-1"
            class="c-input--icon btnOpenModalDirecitionClass"
            v-on:click="addAddressFavorite('origin', null)"
          >
            <img
              v-if="originAddress.favorite === true"
              src="~@/assets/img/icon/icon-estrella-on.svg"
              alt="favorite"
              loading="lazy"
              class="btn-open-modal-direction"
            />
            <img
              v-else
              src="~@/assets/img/icon/icon-estrella-set.svg"
              alt="favorite"
              loading="lazy"
              class="btn-open-modal-direction"
            />
          </div>
        </div>

        <div class="c-input input-field mb-2">
          <input
            type="text"
            ref="originAddressDetail"
            @change="editOriginAddressDetail"
            v-on:focus="
              isOriginFocused = false;
              isDestinationFocused = false;
            "
            placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
            maxlength="150"
          />
          <label for class="active">Referencia</label>
        </div>

        <article
          v-if="!originContact"
          id="btnModalContactFavorite"
          class="c-grid--services__item pt-0 pb-0 curso-pointer"
        >
          <a @click="editOriginContact()"
            ><div id="" class="card c-card c-card--service-box d-flex p-1">
              <div class="card-image">
                <div class="card-title color-text-black">
                  <span class="card-sub-title color-text-black">Escoge tu</span
                  ><span class="card-option">contacto</span>
                </div>
              </div>
              <img
                src="~@/assets/img/service-point-sharff-3.svg"
                class="translatex"
              /></div
          ></a>
        </article>
        <div
          v-if="originContact"
          id="c-contact-send-2"
          class="c-btn-contact-click c-content--spacing-block curso-pointer"
        >
          <div
            class="collection c-collection c-collection--not-hover c-collection--card"
          >
            <div
              class="collection-item collection-item--arrow-next collection-item--icon"
            >
              <a
                @click="editOriginContact()"
                class="collection-item--icon collection-item--icon--user"
                ><div class="c-text-medium font-weight-bold">
                  {{ originContact.name }}
                </div>
                <div class="c-text-medium">
                  {{ originContact.phone }}
                </div></a
              >
            </div>
          </div>
        </div>
      </div>
      <br />
      <h2>Lugar de entrega</h2>
      <div class="div" v-for="(n, i) in numberDestinations" v-bind:key="i">
        <div class="c-input input-field curso-pointer">
          <vue-google-autocomplete
            :ref="'destinationAddress-' + i"
            :id="'c-focus-open-modal-search-direction-send-' + i"
            classname="curso-pointer"
            placeholder=""
            v-on:placechanged="getDestinationAddressData"
            country="pe"
            v-on:focus="
              isDestinationFocused = true;
              isOriginFocused = false;
              indexDestFocused = i;
            "
          ></vue-google-autocomplete>
          <label for="">Dirección</label>
          <div
            :id="'c-icon-favorite-' + i"
            class="c-input--icon btnOpenModalDirecitionClass"
            v-on:click="addAddressFavorite('destination', i)"
          >
            <img
              v-if="destinations[i] && destinations[i].favorite === true"
              src="~@/assets/img/icon/icon-estrella-on.svg"
              alt="favorite"
              loading="lazy"
              class="btn-open-modal-direction"
            />
            <img
              v-else
              src="~@/assets/img/icon/icon-estrella-set.svg"
              alt="favorite"
              loading="lazy"
              class="btn-open-modal-direction"
            />
          </div>
        </div>

        <div class="c-input input-field mb-2">
          <input
            type="text"
            :ref="'destinationAddressDetail-' + i"
            @change="editDestinationAddressDetail(i)"
            v-on:focus="
              isOriginFocused = false;
              isDestinationFocused = false;
            "
            placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
            maxlength="150"
          />
          <label for class="active">Referencia</label>
        </div>

        <article
          v-if="!destinationContact[i]"
          :id="'chooseDestContact-' + i"
          class="c-grid--services__item pt-0 pb-0 curso-pointer"
        >
          <a @click="editDestContact(i)"
            ><div id="" class="card c-card c-card--service-box d-flex p-1">
              <div class="card-image">
                <div class="card-title color-text-black">
                  <span class="card-sub-title color-text-black">Escoge tu</span
                  ><span class="card-option">contacto</span>
                </div>
              </div>
              <img
                src="~@/assets/img/service-point-sharff-3.svg"
                class="translatex"
              /></div
          ></a>
        </article>
        <div
          :id="'c-contact-send-' + i"
          class="c-btn-contact-click c-content--spacing-block curso-pointer"
          v-if="destinationContact[i]"
        >
          <div
            class="collection c-collection c-collection--not-hover c-collection--card"
          >
            <div
              class="collection-item collection-item--arrow-next collection-item--icon"
            >
              <a
                @click.prevent="editDestContact(i)"
                class="collection-item--icon collection-item--icon--user"
                ><div class="c-text-medium font-weight-bold">
                  {{ destinationContact[i].name }}
                </div>
                <div class="c-text-medium">
                  N° {{ destinationContact[i].phone }}
                </div></a
              >
            </div>
          </div>
        </div>
      </div>

      <favorite-address
        id="favoriteOriginAddress"
        v-show="isOriginFocused === true"
        @getSelectedAddress="getOriginSelectedAddress"
        modalId="modal-contact-origin"
      />

      <favorite-address
        id="favoriteDestinationAddress"
        v-show="isDestinationFocused === true"
        @getSelectedAddress="getDestinationSelectedAddress"
        modalId="modal-contact-destination"
      />
    </div>
  </article>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import M from "materialize-css";
import FavoriteAddress from "../../General/FavoriteAddress.vue";

export default {
  name: "Step1-Content",
  components: {
    VueGoogleAutocomplete,
    FavoriteAddress,
  },
  data() {
    return {
      numberDestinations: 1,
      indexDestFocused: null,
      isOriginFocused: false,
      isDestinationFocused: false,
      addressCoverageValidation: true,
    };
  },
  mounted() {
    this.$eventBus.$on("validateExpressStep1", () => {
      this.validateExpressStep1();
    });

    this.$nextTick(() => {
      this.$store.dispatch("EXPRESS_PROGRESS_NAV_STEP", 1);
      this.$store.dispatch("STYLE_SERVICES_W_MAP_DIV");
      this.$store.dispatch("STYLE_EXPRESS_STEP_DIV_W_BG");
      this.$store.dispatch("EXPRESS_ORDER_CONTACT_CLEAN");
      this.$store.dispatch("STYLE_MAP_SAIO", true);
    });
  },
  methods: {
    editDestinationAddressDetail(index) {
      const { value } = this.$refs[`destinationAddressDetail-${index}`][0];
      if (value) {
        if (!this.destinations[index]) {
          this.$refs[`destinationAddressDetail-${index}`][0].value = null;
          this.showError("Primero rellene el campo dirección.");
        } else {
          this.destinations[index].addressDetail = value;
        }
      }
    },
    editOriginAddressDetail() {
      const { value } = this.$refs.originAddressDetail;
      if (value) {
        this.originAddress.addressDetail = value;
      }
    },
    addNewDestination() {
      const index = this.numberDestinations - 1;
      if (this.destinations[index] && this.destinationContact[index]) {
        if (this.destinations[index].lat && this.destinations[index].lng) {
          this.numberDestinations += 1;
        } else {
          this.showError(
            "Por favor, complete correctamente el lugar de entrega previo."
          );
        }
      } else {
        this.showError(
          "Por favor, complete correctamente el lugar de entrega previo y su contacto de destino."
        );
      }
    },
    async validateCoverage(lat, lng) {
      const $this = this;
      // this.showLoading();
      const dato = await $this.mapObject.validateCoverage(lat, lng);
      if (dato) {
        $this.$swal.close();
        this.addressCoverageValidation = true;
      } else {
        $this.$swal.close();
        this.addressCoverageValidation = false;
        this.showError("Debe elegir una direccion dentro de la cobertura");
      }

      // this.showLoading();
      // const payload = {
      //   lat,
      //   lng,
      //   isServiceExpress: true,
      //   isServiceProgrammed: false,
      // };

      // await this.$store
      //   .dispatch("POST_VALIDATE_COVERAGE", payload)
      //   .then(() => {
      //     this.addressCoverageValidation = true;
      //     this.$swal.close();
      //   })
      //   .catch((error) => {
      //     this.$swal.close();

      //     this.addressCoverageValidation = false;
      //     this.showError(
      //       error
      //         ? error.data.message
      //         : "Sesion expirada, por favor vuelva a ingresar."
      //     );
      //   });
    },
    addAddressFavorite(type, index) {
      let isValid = true;
      let errorMsg = "";
      if (type === "origin") {
        if (
          !this.originAddress.description ||
          !this.originAddress.lat ||
          !this.originAddress.lng
        ) {
          isValid = false;
          errorMsg =
            "Por favor ingrese una dirección válida de la lista de favoritos o de la lista desplegable";
        } else if (this.originAddress.favorite === true) {
          errorMsg = "Esta dirección ya ha sido guardada como favorita.";
          isValid = false;
        } else {
          this.$eventBus.$emit(
            "updateAddressInput",
            this.originAddress.description
          );
          this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.originAddress);
          this.originAddress.favorite = true;
        }
      } else if (index !== null && this.destinations.length > 0) {
        const found = this.destinations.find((x) => x.index === index);
        if (found) {
          if (
            !this.destinations[index].description ||
            !this.destinations[index].lat ||
            !this.destinations[index].lng
          ) {
            isValid = false;
          } else if (this.destinations[index].favorite === true) {
            isValid = false;
            errorMsg = "Esta dirección ya ha sido guardada como favorita.";
          } else {
            this.$eventBus.$emit(
              "updateAddressInput",
              this.destinations[index].description
            );
            this.$store.dispatch("HELPER_SET_ADD_ADDRESS", found);
            this.destinations[index].favorite = true;
          }
        } else {
          isValid = false;
        }
      }

      if (isValid === true) {
        this.openModal("modal-add-address");
      } else {
        this.showError(errorMsg);
      }
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite =
              document.querySelector("#modal-contact");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false,
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    editOriginContact() {
      this.openContactModal("origin");
    },
    editDestContact(i) {
      this.$store.dispatch("EXPRESS_ORDER_CURRENT_CONTACT_DEST_INDEX", i);
      this.openContactModal("destination");
    },
    async getOriginAddressData(addressData, placeResult) {
      await this.validateCoverage(addressData.latitude, addressData.longitude);

      if (this.addressCoverageValidation === true) {
        this.originAddress.description = placeResult.formatted_address;
        this.originAddress.lat = addressData.latitude;
        this.originAddress.lng = addressData.longitude;
        this.originAddress.favorite = false;

        this.isOriginFocused = false;
        // var position = {
        //   Lat: this.originAddress.lat,
        //   Lng: this.originAddress.lng,
        // };

        // this.setDraggableOriginMarker(position, placeResult.formatted_address);

        this.renderAddressWaypoints();
      } else {
        this.$refs.originAddress.clear();
      }
    },
    async getDestinationAddressData(addressData, placeResult) {
      await this.validateCoverage(addressData.latitude, addressData.longitude);

      if (this.addressCoverageValidation === true) {
        const destinationAddress = {
          index: this.indexDestFocused,
          description: placeResult.formatted_address,
          lat: addressData.latitude,
          lng: addressData.longitude,
          favorite: false,
        };
        if (this.destinations[this.indexDestFocused]) {
          this.destinations[this.indexDestFocused].description =
            destinationAddress.description;
          this.destinations[this.indexDestFocused].lat = destinationAddress.lat;
          this.destinations[this.indexDestFocused].lng = destinationAddress.lng;
          this.destinations[this.indexDestFocused].favorite =
            destinationAddress.favorite;
        } else {
          this.destinations.push(destinationAddress);
        }
        this.isDestinationFocused = false;
        this.$store.dispatch(
          "EXPRESS_ORDER_CURRENT_CONTACT_DEST_INDEX",
          this.indexDestFocused
        );
        // var position = {
        //   Lat: this.destinations[this.indexDestFocused].lat,
        //   Lng: this.destinations[this.indexDestFocused].lng,
        // };
        // this.setDraggableDestinationMarker(
        //   position,
        //   destinationAddress.description,
        //   this.indexDestFocused
        // );

        this.renderAddressWaypoints();
      } else {
        this.$refs[`destinationAddress-0`][0].clear();
      }
    },
    async getOriginSelectedAddress(favoriteLocation) {
      await this.validateCoverage(
        favoriteLocation.location.Lat,
        favoriteLocation.location.Lng
      );
      if (this.addressCoverageValidation === true) {
        this.originAddress.description = favoriteLocation.address;
        this.originAddress.addressDetail = favoriteLocation.reference;
        this.$refs.originAddressDetail.value = favoriteLocation.reference;
        this.originAddress.lat = favoriteLocation.location.Lat;
        this.originAddress.lng = favoriteLocation.location.Lng;
        this.originAddress.favorite = favoriteLocation.favorite;
        this.$refs.originAddress.update(favoriteLocation.address);
        this.isOriginFocused = false;
        this.isDestinationFocused = false;

        // var position = {
        //   Lat: this.originAddress.lat,
        //   Lng: this.originAddress.lng,
        // };
        // this.setDraggableOriginMarker(position, favoriteLocation.address);
        this.renderAddressWaypoints();
      } else {
        this.$refs.originAddress.clear();
        this.originAddress.description = null;
        this.originAddress.lat = null;
        this.originAddress.lng = null;
        this.$refs.originAddress.update(null);
      }
    },
    async getDestinationSelectedAddress(favoriteLocation) {
      await this.validateCoverage(
        favoriteLocation.location.Lat,
        favoriteLocation.location.Lng
      );
      if (this.addressCoverageValidation === true) {
        const destinationAddress = {
          index: this.indexDestFocused,
          description: favoriteLocation.address,
          addressDetail: favoriteLocation.reference,
          lat: favoriteLocation.location.Lat,
          lng: favoriteLocation.location.Lng,
          favorite: true,
        };
        if (this.destinations[this.indexDestFocused]) {
          this.destinations[this.indexDestFocused].description =
            destinationAddress.description;
          this.descriptions[this.indexDestFocused].addressDetail =
            destinationAddress.addressDetail;
          this.destinations[this.indexDestFocused].lat = destinationAddress.lat;
          this.destinations[this.indexDestFocused].lng = destinationAddress.lng;
        } else {
          this.destinations.push(destinationAddress);
        }
        this.isDestinationFocused = false;
        this.$store.dispatch(
          "EXPRESS_ORDER_CURRENT_CONTACT_DEST_INDEX",
          this.indexDestFocused
        );

        this.$refs[`destinationAddress-${this.indexDestFocused}`][0].update(
          favoriteLocation.address
        );
        this.$refs[
          `destinationAddressDetail-${this.indexDestFocused}`
        ][0].value = favoriteLocation.reference;
        this.isOriginFocused = false;
        this.isDestinationFocused = false;

        // var position = {
        //   Lat: this.destinations[this.indexDestFocused].lat,
        //   Lng: this.destinations[this.indexDestFocused].lng,
        // };
        // this.setDraggableDestinationMarker(
        //   position,
        //   this.indexDestFocused,
        //   destinationAddress.description
        // );

        this.renderAddressWaypoints();
      } else if (this.destinations[this.indexDestFocused]) {
        this.destinations[this.indexDestFocused].description = null;
        this.destinations[this.indexDestFocused].lat = null;
        this.destinations[this.indexDestFocused].lng = null;
        this.$refs[`destinationAddress-${this.indexDestFocused}`][0].update(
          null
        );
        this.$refs[`destinationAddress-${this.indexDestFocused}`][0].clear();
      }
    },
    validateExpressStep1() {
      if (
        !this.originAddress ||
        !this.originAddress.lat ||
        !this.originAddress.lng ||
        !this.originAddress.description
      ) {
        this.showError(
          "Por favor ingrese una dirección válida de la lista de favoritos o de la lista desplegable"
        );
        return false;
      }
      if (!this.destinations.length > 0) {
        this.showError("Debe ingresar al menos 1 destino.");
        return false;
      }
      const found = this.destinations.find(
        (x) => !x.lat || !x.lng || !x.description
      );
      if (found) {
        this.showError(
          "Todos los destinatarios debe tener una dirección válida de la lista de favoritos o de la lista desplegable"
        );
        return false;
      }

      if (
        this.destinations.length !== this.destinationContact.length ||
        !this.originContact
      ) {
        this.showError(
          "Todas las destinatarios deben tener una dirección valida y un contacto seleccionado."
        );
        return false;
      }

      if (this.numberDestinations !== this.destinations.length) {
        this.showError(
          "Posee un destinatario vacio. Por favor, ingrese la información correctamente."
        );
        return false;
      }
      this.$store.dispatch("EXPRESS_PROGRESS_NAV_STEP", 2);
      this.$store.dispatch("STYLE_MAP_SAIO", true);
      this.$store.dispatch("STYLE_SERVICES_W_MAP_DIV");
      this.$store.dispatch("STYLE_EXPRESS_STEP_DIV_WO_BG");
      return true;
    },
    setDraggableOriginMarker(position, formatted) {
      const $this = this;
      const originMarker = $this.mapObject.getMarkerById("origin-1");
      if (position) {
        if (!originMarker) {
          $this.mapObject.setMarker(
            position,
            null,
            true,
            (event) => {
              $this.showLoading();
              const pos = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              };

              const payload = {
                lat: pos.lat,
                lng: pos.lng,
                isServiceExpress: true,
                isServiceProgrammed: false,
              };
              $this.$store
                .dispatch("POST_VALIDATE_COVERAGE", payload)
                .then(() => {
                  $this.mapObject.geocodeLocation(pos, (result) => {
                    $this.originAddress.description = result.formatted;
                    $this.originAddress.lat = result.position.lat;
                    $this.originAddress.lng = result.position.lng;
                    $this.originAddress.favorite = false;
                    $this.$refs.originAddress.update(result.formatted);
                    $this.mapObject.updateMarkerPositionById("origin-1", pos);
                    $this.mapObject.updateMarkerTitleById(
                      "origin-1",
                      result.formatted
                    );
                    $this.$swal.close();
                  });

                  $this.mapObject.centerMap(pos);
                })
                .catch((error) => {
                  $this.addressCoverageValidation = false;
                  $this.showError(
                    error ? error.data.message : "Error Interno del sistema."
                  );
                  $this.originAddress.description = null;
                  $this.originAddress.lat = null;
                  $this.originAddress.lng = null;
                  $this.originAddress.favorite = false;
                  $this.$refs.originAddress.update(null);
                  $this.mapObject.centerMap(pos);
                });
            },
            null,
            "origin-1"
          );
        } else {
          const pos = {
            lat: position.Lat,
            lng: position.Lng,
          };
          originMarker.setPosition(pos);
          originMarker.setTitle(formatted);
          $this.mapObject.centerMap(pos);
        }
      }
    },
    setDraggableDestinationMarker(position, formatted, index) {
      const $this = this;
      const destinationMarker = $this.mapObject.getMarkerById(
        `destination-${index}`
      );
      if (position) {
        if (!destinationMarker) {
          $this.mapObject.setMarker(
            position,
            null,
            true,
            (event) => {
              $this.showLoading();
              const pos = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              };

              const payload = {
                lat: pos.lat,
                lng: pos.lng,
                isServiceExpress: true,
                isServiceProgrammed: false,
              };
              $this.$store
                .dispatch("POST_VALIDATE_COVERAGE", payload)
                .then(() => {
                  $this.mapObject.geocodeLocation(pos, (result) => {
                    $this.destinations[index].description = result.formatted;
                    $this.destinations[index].lat = result.position.lat;
                    $this.destinations[index].lng = result.position.lng;
                    $this.destinations[index].favorite = false;
                    $this.$refs[`destinationAddress-${index}`][0].update(
                      result.formatted
                    );
                    $this.mapObject.updateMarkerPositionById(
                      `destination-${index}`,
                      pos
                    );
                    $this.mapObject.updateMarkerTitleById(
                      `destination-${index}`,
                      result.formatted
                    );
                    $this.$swal.close();
                  });

                  $this.mapObject.centerMap(pos);
                })
                .catch((error) => {
                  $this.addressCoverageValidation = false;
                  $this.showError(
                    error ? error.data.message : "Error Interno del sistema."
                  );
                  $this.destinations[index].description = null;
                  $this.destinations[index].lat = null;
                  $this.destinations[index].lng = null;
                  $this.destinations[index].favorite = false;
                  $this.$refs[`destinationAddress-${index}`][0].update(null);
                  $this.mapObject.centerMap(pos);
                });
            },
            null,
            `destination-${index}`
          );
        } else {
          const pos = {
            lat: position.Lat,
            lng: position.Lng,
          };
          destinationMarker.setPosition(pos);
          destinationMarker.setTitle(formatted);
          $this.mapObject.centerMap(pos);
        }
      }
    },
    renderAddressWaypoints() {
      const $this = this;
      const origin = {
        location: {
          lat: $this.originAddress.lat,
          lng: $this.originAddress.lng,
        },
        address: $this.originAddress.description,
      };

      const allDestinations = $this.destinations.map((x) => {
        const destination = {
          location: {
            lat: x.lat,
            lng: x.lng,
          },
          address: x.description,
        };

        return destination;
      });

      $this.mapObject.renderWaypoint(origin, allDestinations);
    },
  },
  computed: {
    originContact() {
      return this.$store.getters.EXPRESS_ORIGIN_CONTACT;
    },
    destinationContact() {
      return this.$store.getters.EXPRESS_DESTINATION_CONTACT;
    },
    originAddress() {
      return this.$store.getters.EXPRESS_ORIGIN;
    },
    destinations() {
      return this.$store.getters.EXPRESS_DESTINATIONS;
    },
    mapObject() {
      return this.$store.getters.EXPRESS_MAP_OBJECT;
    },
  },
};
</script>

<style></style>
