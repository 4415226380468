<template>
  <div
    id="modalDetailQuoteCT"
    class="modal c-modal c-modal--calculator p-0"
    tabindex="0"
    style="
      z-index: 1003;
      display: none;
      opacity: 0;
      top: 4%;
      transform: scaleX(0.8) scaleY(0.8);
    "
  >
    <div class="container">
      <img
        v-if="showClose"
        @click="closeModal"
        class="close-btn-modal"
        src="~@/assets/img/icon/icon-closex.svg"
        style="position: absolute; top: 20px; right: 20px; cursor: pointer"
      />
      <div class="header">
        <span style=""> resumen del pedido </span>
      </div>
      <div class="content pt-2">
        <div style="padding-bottom: 30px">
          <div class="col s12">
            <div class="row-detail-title">
              <span style="font-weight: bolder">Detalles de la orden</span>
            </div>
            <div class="row-detail">
              <span>Nombre</span>
              <span>{{ destinationContact.name }}</span>
            </div>
            <div class="row-detail">
              <span>Documento</span>
              <span>{{ consigneeDocumentNumber }}</span>
            </div>
            <div class="row-detail">
              <span>Dirección de entrega</span>
              <span
                >{{ destinationAddress.description }}
                {{ destinationAddress.additionalAddress }}</span
              >
            </div>
            <div class="row-detail">
              <span>Teléfono de contacto</span>
              <span>{{ destinationContact.phone }}</span>
            </div>
            <div class="row-detail-title mt-2">
              <span style="font-weight: bolder">Detalles del paquete</span>
            </div>
            <div class="row-detail">
              <span>Descripción</span>
              <ul>
                <li v-for="cat in categoriesText" :key="cat">
                  {{ cat }}
                </li>
              </ul>
            </div>
            <div class="row-detail">
              <span>Cantidad</span>
              <span>{{ order.quantity }}</span>
            </div>
            <div class="row-detail">
              <span>Precio</span>
              <span>$ {{ order.value }}</span>
            </div>
            <div class="row-detail">
              <span>Peso</span>
              <span>{{ order.weight }} Kg</span>
            </div>
            <div class="row-detail-title mt-2">
              <span style="font-weight: bolder">Detalles de tarifa</span>
            </div>
            <div class="row-detail">
              <span style="font-weight: bolder"
                >Estimado de aranceles a la Aduana</span
              >
              <span style="font-weight: bolder">$USD</span>
            </div>
            <div class="row-detail">
              <span>Ad-valorem</span>
              <span>$ {{ resQuote.addvalorem }}</span>
            </div>
            <div class="row-detail">
              <span>IGV</span>
              <span>$ {{ resQuote.arancelesIGV }}</span>
            </div>
            <div class="row-detail">
              <span>IPM</span>
              <span>$ {{ resQuote.IPM }}</span>
            </div>
            <div class="row-detail" style="margin-top: 20px">
              <span style="font-weight: bolder"
                >Estimado de pagos a Sharf</span
              >
              <span style="font-weight: bolder">$USD</span>
            </div>
            <div class="row-detail">
              <span>Servicio*</span>
              <span>$ {{ resQuote.servicioSinIGV }}</span>
            </div>
            <div class="row-detail">
              <span>Distribución</span>
              <span>$ {{ resQuote.distribucionSinIGV }}</span>
            </div>
            <div class="row-detail">
              <span>Subtotal operación grabada</span>
              <span>$ {{ resQuote.subTotalOperacionGravada }}</span>
            </div>
            <div class="row-detail">
              <span>IGV (18%)</span>
              <span>$ {{ resQuote.subTotalOperacionIGV }}</span>
            </div>
            <div class="row-detail" style="margin-top: 20px">
              <span style="font-weight: bolder"
                >Total estimado de aranceles a la Aduana</span
              >
              <span style="font-weight: bolder"
                >$ {{ resQuote.aranceles }}</span
              >
            </div>
            <div class="row-detail">
              <span style="font-weight: bolder"
                >Total estimado de pagos a Sharf</span
              >
              <span style="font-weight: bolder"
                >$ {{ resQuote.totalEstimadoPagoScharff }}</span
              >
            </div>
            <div class="row-detail">
              <span style="font-weight: bolder">Descuento</span>
              <span style="font-weight: bolder"
                >$ {{ resQuote.descuento }}</span
              >
            </div>
            <div class="row-detail">
              <span style="font-weight: bolder; font-family: 'Manrope'"
                >Pago total por el servicio</span
              >
              <span style="font-weight: bolder">$ {{ resQuote.total }}</span>
            </div>
          </div>
          <div class="col s12 mt-4">
            <button
              v-on:click="handleSubmit()"
              class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
            >
              Pagar y enviar $ {{ resQuote.total.toFixed(2) }}
            </button>
          </div>
        </div>
        <div class="pb-4" style="font-size: 0.7rem">
          <span>
            * Servicio: Consolidación en almacenes Miami, transporte
            internacional de USA a Perú, proceso de declaración y liberación
            ante la Aduana y almacenaje.
          </span>
          <br />
          <br />
          <span
            >Las tarifas son referenciales y su valor será más cercano al costo
            real entre más específica sea la información que ingreses en el
            formulario. (Precios válidos solo para los trayectos de Lima a
            provincias y de Lima a Lima)</span
          >
          <br />
          <br />
          <span
            >Si desea más información, puedes revisar nuestros
            <a
              target="popup"
              href="https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf"
              style="color: #ff565d"
              >Términos y condiciones</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

export default {
  name: "QuoterCmp",
  props: {
    resQuote: {
      required: true,
    },
    showClose: {
      default: true,
    },
  },
  data() {
    return {
      // categoriesText: [],
      error: false,
    };
  },
  mounted() {
    // this.categoriesText = [];
    // this.order.categoryIds.forEach((categoryId) => {
    //   this.categoriesText.push(
    //     this.$store.getters.COMPRATRAE_CATEGORIES_BY_ID(categoryId).text
    //   );
    // });
  },
  computed: {
    categoriesText() {
      return this.order.categoryIds.map(
        (categoryId) =>
          this.$store.getters.COMPRATRAE_CATEGORIES_BY_ID(categoryId).text
      );
    },
    order() {
      return this.$store.getters.COMPRATRAE_ORDER;
    },
    destinationContact() {
      return this.$store.getters.COMPRATRAE_DESTINATION_CONTACT;
    },
    destinationAddress: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_DESTINATION_ADDRESS;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_DESTINATION_ADDRESS", value);
      },
    },
    consigneeDocumentNumber: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_NUMBER;
      },
      set(value) {
        this.$store.dispatch(
          "COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_NUMBER",
          value
        );
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$eventBus.$emit("processCT");
    },
    closeModal() {
      const modalCalculator = document.querySelector("#modalDetailQuoteCT");
      const instance2 = M.Modal.getInstance(modalCalculator);
      instance2.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-height: 90vh;
  overflow-x: auto;
  position: relative;
}
.header {
  height: 120px;
  width: 100%;
  background-color: var(--g-color-first);
  border-radius: 0% 0% 80px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    font-size: 2.5rem;
    font-weight: bolder;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}

.row-detail {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px #cfcfcf;
  margin-top: 10px;
  padding-bottom: 5px;
  &-title {
    justify-content: center;
    text-align: center;
    font-size: 1.35rem;
  }
}
</style>
