<template>
  <article id="c-search-inputs" class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <h2>Indicar dirección de entrega</h2>
      <input-district
        id="destinationDistrict"
        text="Buscar distrito"
        @getDistrictId="setDestinationDistrict"
        @getProvinceId="setDestinationProvince"
        @getDepartmentId="setDestinationDepartment"
        serviceType="3"
        v-on:focus="isDestinationFocused = false"
      />
      <!-- <div class="c-input input-field curso-pointer">
        <vue-google-autocomplete
          ref="destinationAddress"
          id="c-focus-open-modal-search-direction"
          class="curso-pointer"
          placeholder
          v-on:placechanged="getDestinationAddressData"
          country="pe"
          v-on:focus.native="isDestinationFocused = true"
        ></vue-google-autocomplete>

        <label for>Dirección</label>

        <div
          v-if="false"
          id="c-icon-favorite-1"
          class="c-input--icon btnOpenModalDirecitionClass"
          v-on:click="AddAddressFavorite('destination', null)"
        >
          <img
            v-if="destinationAddress.favorite === true"
            src="~@/assets/img/icon/icon-estrella-on.svg"
            alt="favorite"
            loading="lazy"
            class="btn-open-modal-direction"
          />
          <img
            v-else
            src="~@/assets/img/icon/icon-estrella-set.svg"
            alt="favorite"
            loading="lazy"
            class="btn-open-modal-direction"
          />
        </div>
      </div> -->

      <div class="c-input input-field mb-2">
        <input
          type="text"
          v-model="destinationAddress.description"
          @focus="isDestinationFocused = true"
        />
        <label for class="active">Dirección</label>
        <div
          id="c-icon-favorite-1"
          class="c-input--icon btnOpenModalDirecitionClass"
          v-on:click="AddAddressFavorite('destination', null)"
        >
          <img
            v-if="destinationAddress.favorite === true"
            src="~@/assets/img/icon/icon-estrella-on.svg"
            alt="favorite"
            loading="lazy"
            class="btn-open-modal-direction"
          />
          <img
            v-else
            src="~@/assets/img/icon/icon-estrella-set.svg"
            alt="favorite"
            loading="lazy"
            class="btn-open-modal-direction"
          />
        </div>
      </div>

      <div class="c-input input-field mb-2">
        <input
          v-model="destinationAddress.additionalAddress"
          maxlength="299"
          type="text"
          v-on:focus="isDestinationFocused = false"
          placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
        />
        <label for class="active">Referencia</label>
      </div>
      <h3>Datos de quién recibe</h3>
      <article
        v-if="destinationContact === null"
        id="btnModalContactFavorite"
        class="c-grid--services__item pt-0 pb-0"
      >
        <a @click="openContactModal('destination')"
          ><div id="" class="card c-card c-card--service-box d-flex p-1">
            <div class="card-image">
              <div class="card-title color-text-black">
                <span class="card-sub-title color-text-black">Escoge tu</span
                ><span class="card-option">contacto</span>
              </div>
            </div>
            <img
              src="@/assets/img/service-point-sharff-3.svg"
              class="translatex"
            /></div
        ></a>
      </article>
      <div
        v-if="destinationContact !== null"
        id="c-contact-send-2"
        class="c-btn-contact-click c-content--spacing-block"
      >
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
          >
            <a
              @click="editDestinationContact()"
              class="collection-item--icon collection-item--icon--user"
              ><div class="c-text-medium font-weight-bold">
                {{ destinationContact.name }}
              </div>
              <div class="c-text-medium">
                {{ destinationContact.phone }}
              </div></a
            >
          </div>
        </div>
      </div>
      <favorite-address
        id="favoriteDestinationAddress"
        v-show="isDestinationFocused === true"
        @getSelectedAddress="getDestinationSelectedAddress"
        modalId="modal-contact-destination"
      />
    </div>
  </article>
</template>
<script>
import M from "materialize-css";
import InputDistrict from "../../General/InputDistrict.vue";
import FavoriteAddress from "../../General/FavoriteAddress.vue";

export default {
  components: { InputDistrict, FavoriteAddress },
  data() {
    return {
      isDestinationFocused: false,
    };
  },
  mounted() {
    this.$eventBus.$on("validateCompraTraeStep4", () => {
      this.validateStep4();
    });
  },
  methods: {
    setDestinationDistrict(id) {
      this.$store.dispatch("COMPRATRAE_DESTINATION_DISTRICT", id);
    },
    setDestinationProvince(id) {
      this.$store.dispatch("COMPRATRAE_DESTINATION_PROVINCE", id);
    },
    setDestinationDepartment(id) {
      this.$store.dispatch("COMPRATRAE_DESTINATION_DEPARTMENT", id);
    },
    AddAddressFavorite(type) {
      if (type === "destination") {
        this.$eventBus.$emit(
          "updateAddressInput",
          this.destinationAddress.description
        );

        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.destinationAddress);
        this.destinationAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editDestinationContact() {
      this.openContactModal("destination");
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite =
              document.querySelector("#modal-contact");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false,
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    getDestinationSelectedAddress(favoriteLocation) {
      this.destinationAddress.description = favoriteLocation.address;
      this.destinationAddress.additionalAddress = favoriteLocation.reference;
      this.destinationAddress.lat = favoriteLocation.location.Lat;
      this.destinationAddress.lng = favoriteLocation.location.Lng;
      this.$refs.destinationAddress.update(favoriteLocation.address);
      this.isDestinationFocused = false;
      this.map.setMarker(favoriteLocation.location, null, true);

      // this.saveDestinationAddress();
      // this.openContactModal("destination");
    },
    getDestinationAddressData(addressData, placeResult) {
      this.destinationAddress.description = placeResult.formatted_address;
      this.destinationAddress.lat = addressData.latitude;
      this.destinationAddress.lng = addressData.longitude;
      this.isDestinationFocused = false;
      this.saveDestinationAddress();
    },
    saveDestinationAddress() {
      this.$store.dispatch(
        "COMPRATRAE_ORDER_DESTINATION_ADDRESS",
        this.destinationAddress
      );
    },
    validateStep4() {
      if (!this.destinationDistrict.id) {
        this.showError("Por favor, seleccione un distrito.");
        return false;
      }

      if (this.destinationAddress.description === "") {
        this.showError("Por favor, ingrese una dirección.");
        return false;
      }

      if (this.destinationContact === null) {
        this.showError("Por favor, seleccione un contacto.");
        return false;
      }

      this.calculateFare();

      this.$store.dispatch("COMPRATRAE_PROGRESS_NAV_STEP", 5);
      return true;
    },
    calculateFare() {
      this.showLoading("Calculando...");

      const order = this.$store.getters.COMPRATRAE_ORDER;

      const requestData = {
        weight: order.weight,
        value: order.value,
        provinceId: order.destination.provinceId,
        couponId: order.coupon !== null ? order.coupon.id : null,
      };

      this.$store
        .dispatch("COMPRATRAE_CALCULATE_FARE", requestData)
        .then((response) => {
          this.$swal.close();
          const data = response;

          this.aranceles = data.aranceles;
          this.descuento = data.descuento;
          this.distribucion = data.distribucion;
          this.flete = data.flete;
          this.igv = data.igv;
          this.impuestos = data.impuestos;
          this.margen = data.margen;
          this.precioSinIGV = data.precioSinIGV;
          this.servicio = data.servicio;
          this.shipping = data.shipping;
          this.total = data.total;
          this.totalUSA = data.totalUSA;

          this.$store.dispatch("COMPRATRAE_ORDER_FARE", data);
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
  computed: {
    destinationContact() {
      return this.$store.getters.COMPRATRAE_DESTINATION_CONTACT;
    },
    destinationAddress: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_DESTINATION_ADDRESS;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_DESTINATION_ADDRESS", value);
      },
    },
    destinationDistrict: {
      get() {
        return this.$store.getters.COMPRATRAE_DESTINATION_DISTRICT;
      },
    },

    // destinationDistrict: {
    //     get() {
    //         return this.$store.getters.COMPRATRAE_DESTINATION_DISTRICT
    //     },
    //     // set(value) {
    //     //     this.$store.dispatch("COMPRATRAE_DESTINATION_DISTRICT", value);
    //     // }
    // },
    // destinationProvince: {
    //     get() {
    //         return this.$store.getters.COMPRATRAE_DESTINATION_PROVINCE
    //     },
    //     // set(value) {
    //     //     this.$store.dispatch("COMPRATRAE_DESTINATION_PROVINCE", value);
    //     // }
    // },
  },
};
</script>
<style lang=""></style>
