<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <button
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      @click="callValidation"
    >
      Continuar
    </button>
  </div>
</template>

<script>
export default {
  name: "Step1-Footer",
  methods: {
    callValidation() {
      this.$eventBus.$emit("validateExpressStep1");
    },
  },
};
</script>

<style></style>
