<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <button
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="callValidateStep3"
    >
      Continuar
    </button>
  </div>
</template>

<script>
export default {
  name: "Step3-Footer",
  methods: {
    callValidateStep3() {
      this.$eventBus.$emit("validateCompraTraeStep3");
    },
  },
};
</script>
