<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <div class="c-content--spacing-block">
        <h3>1) Paquete</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div class="collection-item collection-item--title">
            Categoria(s):

            <span>{{ categoriesText.join(", ") }}</span>
          </div>
          <div class="collection-item">
            Valor del producto: $ {{ parseFloat(value).toFixed(2) }}
          </div>
        </div>
      </div>
      <div class="c-section--point">
        <div class="c-section--point-delivery">
          <ul>
            <li class="info-black">Dirección de entrega</li>
            <li class="info-light" v-if="order.destination.address">
              {{ order.destination.address.description }}
            </li>
            <li class="info-black">Contacto</li>
            <li class="info-light" v-if="order.contactDestination">
              #{{ order.contactDestination.phone }} -
              {{ order.contactDestination.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="c-content--spacing-block">
        <h3>2) Tipo de comprobante</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
          >
            <a
              href="javascript:void(0)"
              id="btnVoucher"
              @click="openTypeVoucher"
              class="collection-item--icon collection-item--icon--ticket"
              >{{ !voucher ? "Comprobante de Pago" : voucher.typeVoucher }}
            </a>
          </div>
        </div>
      </div>
      <div class="c-content--spacing-block">
        <h3>3) Tarjeta</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
          >
            <a
              href="javascript:void(0)"
              id="openModalTypeCard"
              @click="openTypeCard"
              class="collection-item--icon collection-item--icon--card"
              :class="[card ? card.cardBrand.toLowerCase() : '']"
            >
              {{
                !card
                  ? "Método de pago"
                  : card.cardBrand + " " + card.cardNumber
              }}
            </a>
          </div>
        </div>
      </div>
      <div class="c-content--spacing-block">
        <h3>4) ¿Tienes cupón de descuento?</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div
            class="collection-item collection-item--arrow-next collection-item--icon"
            style="display: flex; align-items: center"
          >
            <span
              style="
                margin-right: 10px;
                color: white;
                border: solid 1px black;
                border-radius: 100%;
                width: 33px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: black;
                cursor: pointer;
              "
              @click="deleteCoupon()"
              v-if="coupon"
              >X</span
            >
            <a
              href="javascript:void(0)"
              id="openModalCoupon"
              @click="openCoupon"
              class="collection-item--icon collection-item--icon--dsct"
            >
              <p
                style="
                  text-overflow: ellipsis;
                  max-width: 200px;
                  overflow: hidden;
                  white-space: nowrap;
                "
              >
                {{ coupon ? coupon.code : "Cupon" }}
              </p>
              <b v-if="coupon">{{ ` (${coupon.descriptiveValue})` }}</b></a
            >
          </div>
        </div>
      </div>

      <div class="c-switch switch">
        <label class="row"
          ><div class="col s2">
            <input type="checkbox" v-model="tyc" /><span class="lever"></span>
          </div>
          <div class="col s10">
            <div class="c-switch__text">
              He leído y acepto:
              <a
                href="https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf"
                target="popup"
                onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf','popup','width=600,height=600'); return false;"
                style="color: rgb(255, 0, 0); text-decoration: underline"
                >Términos y Condiciones</a
              >
            </div>
          </div></label
        >
      </div>
      <div class="c-switch switch">
        <label class="row"
          ><div class="col s2">
            <input type="checkbox" v-model="mercaderia" /><span
              class="lever"
            ></span>
          </div>
          <div class="col s10">
            <div class="c-switch__text">
              He leído y acepto:
              <a
                href="https://scharff-dev.holascharff.com/saio/dev/front/Content/Scharff-Client/pdf/Mercaderias_restringidas.pdf"
                target="popup"
                onclick="window.open('https://scharff-dev.holascharff.com/saio/dev/front/Content/Scharff-Client/pdf/Mercaderias_restringidas.pdf','popup','width=600,height=600'); return false;"
                style="color: rgb(255, 0, 0); text-decoration: underline"
                >Mercadería restringida</a
              >
              y
              <a
                href="https://saio.holascharff.com/Files/mercaderia-prohibida.pdf"
                target="popup"
                onclick="window.open('https://saio.holascharff.com/Files/mercaderia-prohibida.pdf','popup','width=600,height=600'); return false;"
                style="color: rgb(255, 0, 0); text-decoration: underline"
                >Mercadería prohibida</a
              >
            </div>
          </div></label
        >
      </div>
    </div>
  </article>
</template>

<script>
import * as Sentry from "@sentry/vue";
import M from "materialize-css";
import { sendEvent } from "../../../services/others";

export default {
  name: "Step5Content",
  data() {
    return {
      categoriesText: [],
      tyc: false,
      mercaderia: false,
    };
  },
  mounted() {
    this.fetchCards();
    this.$eventBus.$on("validateCompraTraeStep5", () => {
      this.validateStep5();
    });
    this.$eventBus.$on("processCT", () => {
      this.processCT();
    });
    sendEvent();
  },
  methods: {
    async fetchCards() {
      this.showLoading();
      await this.$store
        .dispatch("GET_CARDS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const cards = success.data.result;
            this.$store.dispatch("GENERAL_SET_CARDS", cards);
            if (cards.length > 0) {
              this.$store.dispatch("COMPRATRAE_CARD", cards[0]);
            }
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    openTypeVoucher() {
      this.openModal("modalTypeVoucher");
    },
    openTypeCard() {
      this.openModal("modalTypeCard");
    },
    openTip() {
      this.openModal("modalTip");
    },
    openCoupon() {
      this.openModal("modalCoupon");
    },
    async deleteCoupon() {
      this.$store.dispatch("COMPRATRAE_COUPON", null);
      this.showLoading();
      await this.calculateFare();
      this.$swal.close();
    },
    calculateFare() {
      this.showLoading("Calculando...");

      const order = this.$store.getters.COMPRATRAE_ORDER;

      const requestData = {
        weight: order.weight,
        value: order.value,
        provinceId: order.destination.provinceId,
        couponId: order.coupon !== null ? order.coupon.id : null,
      };

      this.$store
        .dispatch("COMPRATRAE_CALCULATE_FARE", requestData)
        .then((response) => {
          this.$swal.close();
          const data = response;

          this.aranceles = data.aranceles;
          this.descuento = data.descuento;
          this.distribucion = data.distribucion;
          this.flete = data.flete;
          this.igv = data.igv;
          this.impuestos = data.impuestos;
          this.margen = data.margen;
          this.precioSinIGV = data.precioSinIGV;
          this.servicio = data.servicio;
          this.shipping = data.shipping;
          this.total = data.total;
          this.totalUSA = data.totalUSA;

          this.$store.dispatch("COMPRATRAE_ORDER_FARE", data);
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    validateStep5() {
      if (!this.voucher) {
        this.showError("Por favor ingrese los datos del comprobante de pago.");
        return false;
      }

      if (this.totalFare.total !== 0 && !this.card) {
        this.showError("Por favor ingrese un metodo de pago.");
        return false;
      }

      if (!this.tyc) {
        this.showError("Por favor acepte los términos y condiciones.");
        return false;
      }

      if (!this.mercaderia) {
        this.showError(
          "Por favor acepte la política de mercadería restringidas."
        );
        return false;
      }

      this.openModal("modalDetailQuoteCT");
      return true;
    },
    processCT() {
      const $this = this;
      this.showLoading("Procesando...");
      sendEvent(2);
      this.$store
        .dispatch("GET_UUID")
        .then(async (responseUUID) => {
          const UUID = responseUUID.data.result;
          // eslint-disable-next-line no-undef
          initDFP(UUID);
          await this.$store.dispatch("USER_SET_UUID", UUID);
          $this.$store
            .dispatch("COMPRATRAE_CREATE_ORDER")
            .then((response) => {
              console.log(
                "🚀 ~ file: Step5-Content.vue:312 ~ .then ~ response",
                response
              );
              const modalCalculator = document.querySelector(
                "#modalDetailQuoteCT"
              );
              const instance2 = M.Modal.getInstance(modalCalculator);
              instance2.close();
              $this.$store.dispatch("SET_PAYMENT_RESULT", response);
              $this.$swal.close();
              $this.$store.dispatch("COMPRATRAE_PROGRESS_NAV_STEP", 6);
            })
            .catch((error) => {
              console.log(
                "🚀 ~ file: Step5-Content.vue:321 ~ processCT ~ error",
                error
              );
              Sentry.captureException(error, {
                tags: {
                  ErrorType: "Prioritize",
                },
              });
              $this.$swal.close();
              $this.showError(
                error
                  ? error.data.message
                  : "Ocurrio un error inesperado, inicie sesión nuevamente."
              );
              return false;
            });
        })
        .then(async () => {
          await this.timeout(3000);
        });
    },
  },
  computed: {
    totalFare: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_FARE;
      },
    },
    categoryIds: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_CATEGORY_IDS;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_CATEGORY_IDS", value);
      },
    },
    value: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_VALUE;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_VALUE", value);
      },
    },
    otherCategoryText: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_OTHER_CATEGORY_TEXT;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_OTHER_CATEGORY_TEXT", value);
      },
    },
    order() {
      return this.$store.getters.COMPRATRAE_ORDER;
    },
    voucher() {
      return this.$store.getters.COMPRATRAE_VOUCHER;
    },
    card() {
      return this.$store.getters.COMPRATRAE_CARD;
    },
    coupon() {
      return this.$store.getters.COMPRATRAE_COUPON;
    },
  },
  watch: {
    categoryIds(values) {
      this.categoriesText = [];
      values.forEach((categoryId) => {
        this.categoriesText.push(
          this.$store.getters.COMPRATRAE_CATEGORIES_BY_ID(categoryId).text
        );
      });
    },
    stylesCT: {
      handler() {
        this.$store.dispatch("STYLE_SHOW_CT_QUOTER", this.stylesCT.showQuoter);
        this.$store.dispatch("STYLE_MAP_SAIO", this.stylesCT.mapSaio);
      },
      deep: true,
    },
  },
};
</script>
