<template>
  <div class="icon__message">
    <div class="icon__error">
      <svg width="16" height="16" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#E50000">
        <path d="M12 7v6m0 4.01.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#E50000" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <span v-if="isOriginDestinationError" class="message__error origin-destination-error" v-html="messageWithStyles"></span>
    <span v-else class="message__error">
      {{ message }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        default: 'Error'
      },
      isOriginDestinationError: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      messageWithStyles() {
        if (this.isOriginDestinationError) {
          const customStyle = `
            color: #E50000;
            font-size: 14px;
            font-weight: 400;
          `
          const linkStyle = `
            color: #E50000;
            text-decoration: underline;
          `
          const styledMessage = this.message.replace(/<a/g, `<a style="${linkStyle}"`)
          return `<span style="${customStyle}">${styledMessage}</span>`
        }
        return this.message
      },
    },
  }
</script>

<style scoped lang="scss">
.icon__message {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.icon__error {
  padding: 1.3px;
}
a {
  color: #34d170 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-decoration: underline;
}
.message__error {
  color: #E50000;
  font-size: 14px;
  font-weight: 400;
  text-align: left;

  &.origin-destination-error {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>