<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--img center-align">
      <div class="c-content--modalBannerCurved pt-1 pb-1">
        <div class="c-content--title-top">Confirmación exitosa</div>
      </div>
      <div class="c-section--bannerCircle colorSecundaryBanner"></div>
      <img
        class="pt-2"
        src="~@/assets/img/service-aerolina.svg"
        alt
        width="280"
        height="auto"
        load="lazy"
      />
    </div>
    <div class="c-content--app_container">
      <div class="c-content--spacing-block">
        <div class="c-section--content-article">
          <article class="c-grid--services__item">
            <div
              class="c-section--content-date-designation font-weight-bold pr-0 pl-0 pb-4 pt-2 text-center justify-content-center"
            >
              Muy pronto estaremos llegando
            </div>
            <div>
              <SButton
                color="secondary"
                style="width: 100%; margin: 10px 0px"
                @click="shouldShowTrackingModal = true"
                >Registra tu nro. de tracking</SButton
              >
            </div>
            <div
              v-if="payment !== null && payment.data.result !== null"
              style="
                padding-left: 2em;
                padding-bottom: 0.3em;
                line-height: 1.67;
                background: white;
                border: 1px solid #e0e0e0;
                border-radius: 15px;
                padding-top: 1em;
                margin-bottom: 1.5em;"
            >
              <b>nro. de pedido:</b> {{ payment.data.result.purchaseNumber }}
              <br />
              <b>nombre y apellido del cliente:</b>
              {{ payment.data.result.firstName }}
              {{ payment.data.result.lastName }} <br />
              <b>nro. de tarjeta:</b> {{ payment.data.result.cardNumber }}
              <br />
              <b>marca de la tarjeta:</b> {{ payment.data.result.cardBrand }}
              <br />
              <b>fecha y hora del pedido:</b> {{ payment.data.result.date }}
              <br />
              <b>tipo de moneda:</b> {{ payment.data.result.currency }} <br />
              <b>términos y condiciones:</b>
              <a
                href="https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf"
                target="popup"
                onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-compra-y-trae.pdf','popup','width=600,height=600'); return false;"
                style="color: rgb(255, 0, 0); text-decoration: underline"
                >click aquí</a
              >
            </div>
            <div
              style="display: flex; flex-direction: column; padding-bottom: 1em"
            >
              <h3 class="c-texto font-weight-bold">¡Importante!</h3>
              <span class="c-texto font-weight-light"
                >Tu tienda online te enviará un Nro. de Tracking a tu correo,
                recuerda ingresarlo en la pestaña de mis pedidos.</span
              >
            </div>
            <div class="c-section--content-point pb-2">
              <div class="c-section--point-pick">
                <ul>
                  <li class="info-black">Lugar de entrega</li>
                  <li class="info-light">
                    {{ order.destination.address.description }}
                  </li>
                  <li class="info-black">Contacto</li>
                  <li class="info-light">
                    #{{ order.contactDestination.phone }} -
                    {{ order.contactDestination.name }}
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
        <h3>Producto a entregar:</h3>
        <div
          class="collection c-collection c-collection--not-hover c-collection--card"
        >
          <div class="collection-item">
            <div class="collection-item__separate">
              <div class="c-texto font-weight-bold">Valor:</div>
              <div class="c-texto font-weight-light">
                $ {{ parseFloat(order.value).toFixed(2) }}
              </div>
            </div>
          </div>
          <div class="collection-item">
            <div class="collection-item__united">
              <div class="c-texto font-weight-bold">Detalles</div>
              <div class="c-texto font-weight-light">
                Categoria:
                {{ categoriesText.join(", ") }}
              </div>
              <div class="c-texto font-weight-light">
                Cantidad:
                {{ order.quantity }}
              </div>
              <div class="c-texto font-weight-light">
                Peso:
                {{ order.weight }} Kg
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TrackingModal
      v-if="shouldShowTrackingModal"
      :order="orderToTrackingModal"
      @close="shouldShowTrackingModal = false"
      @updateCyTData="updateCyTData"
    />
  </article>
</template>

<script>
import TrackingModal from "./TrackingModal.vue";
import SButton from "../../../library/SButton.vue";

export default {
  name: "OrderConfirmationContent",
  components: { TrackingModal, SButton },

  data() {
    return {
      categoriesText: [],
      shouldShowTrackingModal: false,
      orderToTrackingModal: {},
    };
  },
  mounted() {
    this.categoriesText = [];
    const categoriesIds = this.$store.getters.COMPRATRAE_CATEGORIES;
    categoriesIds.forEach((categoryId) => {
      this.categoriesText.push(
        this.$store.getters.COMPRATRAE_CATEGORIES_BY_ID(categoryId).text
      );
    });
    this.orderToTrackingModal = this.order;
    this.orderToTrackingModal.compraTraeTrackingNumbers = [];
    this.orderToTrackingModal.id = this.payment.data.result.orderId;
  },
  methods: {
    updateCyTData(data) {
      this.orderToTrackingModal.compraTraeTrackingNumbers =
        data.compraTraeTrackingNumbers;
    },
  },
  computed: {
    order() {
      return this.$store.getters.COMPRATRAE_ORDER;
    },
    payment() {
      return this.$store.getters.PAYMENT_RESULT;
    },
    textButtonTrackingModal() {
      return this.orderToTrackingModal.compraTraeTrackingNumbers.length > 0
        ? "Ver tu nro. de tracking"
        : "Registra tu nro. de tracking";
    },
  },
};
</script>

<style scoped></style>
