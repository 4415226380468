<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <button
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="callValidateStep2"
      :disabled="!isValid"
    >
      Continuar
    </button>
  </div>
</template>

<script>
export default {
  name: "Step2-Footer",
  methods: {
    callValidateStep2() {
      this.$eventBus.$emit("validateCompraTraeStep2");
    },
  },
  computed: {
    isValid() {
      return this.value <= 2000 && this.weight <= 90;
    },
    value: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_VALUE;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_VALUE", value);
      },
    },
    weight: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_WEIGHT;
      },
      set(value) {
        this.$store.dispatch("COMPRATRAE_ORDER_WEIGHT", value);
      },
    },
  },
};
</script>
