<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <a
      href="#"
      v-on:click="print()"
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      >imprimir</a
    >
    <a
      class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat"
      href="javascript:void(0)"
      @click="sendToHome"
      >Volver al home</a
    >
  </div>
</template>

<script>
export default {
  name: "OrderConfirmationFooter",
  methods: {
    sendToTracking() {
      window.location.href = `${process.env.VUE_APP_WEBURL}mis-envios`;
    },
    sendToHome() {
      this.$store.dispatch("STYLE_HOME_DIV");
      window.location.href = process.env.VUE_APP_WEBURL;
    },
  },
};
</script>

<style></style>
