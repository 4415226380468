<template>
  <div class="modal">
    <div class="modal-content flex flex-row">
      <div
        @click="$emit('close')"
        class="back-button cursor-pointer"
        style="margin-top: 7px"
      >
        <img src="@/assets/img/icon/icon-arrow-black.svg" alt="" />
      </div>
      <div class="body-modal flex flex-column">
        <slot>
          <span class="font-weight-bold mb-5" style="font-size: 1.5em"
            >{{ textTitle }}
          </span>
          <div
            class="grid grid-cols-2 gap-4 w-full tracking-inputs"
            v-for="(item, index) in trackingNumbers"
            :key="index"
          >
            <div class="flex flex-column">
              <span style="font-size: 0.9em" class="font-semibold"
                >Número de Tracking</span
              >
              <div class="c-input bg-white">
                <input
                  type="text"
                  v-model="item.trackingNumber"
                  placeholder="Ejm: Trk000001"
                  :disabled="!canModify"
                />
              </div>
            </div>
            <div class="flex flex-column">
              <span style="font-size: 0.9em" class="font-semibold"
                >Descripción de compra</span
              >
              <div
                class="c-select curso-pointer bg-white"
                style="height: -webkit-fill-available"
              >
                <select
                  v-model="item.trackingDescriptionId"
                  style="display: block"
                  @change="updateItemText(item)"
                  :disabled="!canModify"
                >
                  <option value disabled="disabled">Seleccionar</option>
                  <option
                    v-for="category in categories"
                    v-bind:value="category.id"
                    v-bind:key="category.id"
                  >
                    {{ category.text }}
                  </option>
                </select>
              </div>
              <div
                class="c-input mt-3 bg-white"
                v-if="item.trackingDescriptionTexto === 'Otros'"
              >
                <input
                  type="text"
                  v-model="item.description"
                  placeholder="Descripción:"
                  :disabled="!canModify"
                />
              </div>
            </div>
          </div>
          <!-- <div v-if="!isOnlyView" class="flex flex-row justify-end">
            <div
              class="c-content--buttond-add d-flex justify-content-center curso-pointer"
            >
              <a
                v-if="trackingNumbers.length < 4"
                @click.prevent="addItem"
                class="d-flex align-items-center color-black font-weight-ligth p-1"
                ><img
                  src="~@/assets/img/icon/icon-add.svg"
                  alt="Icono de agregar tarjeta"
                  width="16"
                  height="16"
                  class="mr-1"
                />Agregar
              </a>
            </div>
          </div> -->
          <div
            class="flex flex-row justify-center mt-5 button-container"
            v-if="canModify"
          >
            <SButton @click="saveTrackings">Registrar</SButton>
          </div>
          <div class="flex flex-row mt-5">
            <span class="font-light text-xs text-justify">{{
              textFooter
            }}</span>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { sendTrackingNumbers } from "../../../services/tracking";
import { getCompraTraeCategories } from "../../../services/compraTrae";

const moment = require("moment");

export default {
  name: "TrackingModal",
  props: {
    order: {
      default: null,
    },
  },
  data() {
    return {
      trackingNumbers: [],
      isOnlyView: false,
      categories: [],
      maxDaysToModify: 10,
      maxDaysToRegister: 15,
    };
  },
  computed: {
    textTitle() {
      return !this.canModify
        ? "Ver Nro. de Tracking Number"
        : "Registra tu Nro. de Tracking Number";
    },

    canModify() {
      return true;
    },
    firstRegisterDate() {
      return this.order.haveTrackingNumber
        ? this.order.compraTraeTrackingNumbers.reduce(
            (prev, current) =>
              moment(prev.fechaCreacion, "DD/MM/YYYY").isBefore(
                moment(current.fechaCreacion, "DD/MM/YYYY")
              )
                ? moment(prev.fechaCreacion, "DD/MM/YYYY")
                : moment(current.fechaCreacion, "DD/MM/YYYY"),
            { fechaCreacion: moment().format("DD/MM/YYYY") }
          )
        : moment();
    },
    exceedMaxDaysToModify() {
      return (
        moment().diff(this.firstRegisterDate, "days") > this.maxDaysToModify
      );
    },
    exceedMaxDaysToRegister() {
      return (
        moment().diff(moment(this.order.date, "DD/MM/YYYY"), "days") >
        this.maxDaysToRegister
      );
    },
    estadoTrackingNumbers() {
      if (this.order.compraTraeTrackingNumbers.length === 0) return "none";
      if (this.order.compraTraeTrackingNumbers.length >= 4) return "full";
      return "some";
    },
    textFooter() {
      if (this.estadoTrackingNumbers === "none") {
        return `¡Tienes ${this.maxDaysToRegister} días para completar! después de eso,
        tendremos que guardar tu compra por $10 adicionales *cobro mensual por Tracking.*`;
      }
      if (this.estadoTrackingNumbers === "some") {
        if (this.exceedMaxDaysToRegister || this.exceedMaxDaysToModify) {
          return `¡Ya completaste correctamente los números de tracking!
          si tienes alguna duda puedes escribirnos a: compraytrae@holascharff.com`;
        }
        return `¿Ya completaste tus números de trackings? Recuerda que tienes ${this.maxDaysToModify} días para
          agregar o editar cualquier número de trackings, contando desde la fecha de registro del pedido.
          ¡Si tienes más de un paquete, es muy importante que ingreses todos!
          Cualquier consulta podrás escribirnos a compraytrae@holascharff.com`;
      }
      if (this.estadoTrackingNumbers === "full") {
        return `¡Ya completaste correctamente los números de tracking!
          si tienes alguna duda puedes escribirnos a: compraytrae@holascharff.com`;
      }
      return "";
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.showLoading("Cargando...");
      await this.retriveCategories();
      if (this.order.compraTraeTrackingNumbers.length > 0) {
        this.trackingNumbers = this.order.compraTraeTrackingNumbers.map(
          (item) => ({
            trackingNumber: item.trackingNumberCode,
            trackingDescriptionId: item.descripcionId,
            trackingDescriptionTexto:
              this.categories.find((cat) => cat.text === item.descriptionTexto)
                ?.text ?? "Otros",
            description: item.descriptionTexto,
            editable: item.editable,
            compraTraeTrackingNumberId: item.compraTraeTrackingNumberId,
          })
        );
      }
      while (this.trackingNumbers.length < 4) {
        this.trackingNumbers = [
          ...this.trackingNumbers,
          {
            compraTraeTrackingNumberId: null,
            trackingNumber: "",
            trackingDescriptionId: 0,
            trackingDescriptionTexto: "",
            description: "",
            editable: true,
          },
        ];
      }
      this.$swal.close();
    },
    async saveTrackings() {
      try {
        this.showLoading("Guardando...");
        const listOfTrackingNumbers = this.trackingNumbers.filter(
          (item) => item.trackingDescriptionId !== 0
        );
        const isValid = !listOfTrackingNumbers.find(
          (item) =>
            (item.trackingNumber !== "" &&
              item.trackingDescriptionTexto === "") ||
            (item.trackingNumber === "" &&
              item.trackingDescriptionTexto !== "") ||
            (item.trackingDescriptionTexto === "Otros"
              ? !item.description || item.description === ""
              : false)
        );
        if (!isValid || listOfTrackingNumbers.length === 0) {
          this.showError("Debes completar todos los campos.");
          return;
        }
        const data = {
          compraTraeTrackingNumbers: listOfTrackingNumbers.map((item) => ({
            pedidoCompraTraeId: this.order.id,
            compraTraeTrackingNumberId: item.compraTraeTrackingNumberId,
            trackingNumberCode: item.trackingNumber,
            descripcionId: item.trackingDescriptionId,
            descriptionTexto: item.description,
            fechaCreacion: new Date(),
            editable: true,
          })),
        };
        await sendTrackingNumbers(data);

        this.$store
          .dispatch("HISTORY_GET_ORDER_CYT_BY_ID", this.order.id)
          .then((response) => {
            this.$store.dispatch("HISTORY_ORDER_DETAIL", response);
            this.$emit("updateCyTData", response);
            this.$emit("close");
          });
        this.$swal.close();
      } catch (error) {
        this.showError(error.response.data.message);
      }
    },
    async retriveCategories() {
      this.categories = await getCompraTraeCategories();
    },
    addItem() {
      this.trackingNumbers = [
        ...this.trackingNumbers,
        {
          trackingNumber: "",
          trackingDescription: "",
          editable: true,
          description: "",
        },
      ];
    },
    updateItemText(item) {
      item.trackingDescriptionTexto = this.categories.find(
        (cat) => cat.id === item.trackingDescriptionId
      ).text;
    },
  },
};
</script>

<style lang="scss" scoped>
/* The Modal (background) */
.modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1001; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  .modal-content {
    @media (max-width: 767px) {
      height: 100vh;
      width: 100vw;
      max-width: 10000px !important;
      margin: 0px;
      // margin-top: 55px;
      border-radius: 0px !important;
      .tracking-inputs {
        display: flex !important;
        flex-direction: column !important;
        margin-bottom: 40px;
      }
      .button-container {
        margin-top: auto;
      }
    }
    background-color: #f3f3f3;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    border-radius: 15px;
    height: fit-content;
    max-width: 600px;
    .body-modal {
      width: -webkit-fill-available;
      padding: 0px 10px;
      overflow-x: auto;
    }
  }
}

/* Modal Content/Box */
</style>
