<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <a
      class="c-button c-button--four c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="next"
      >Ver detalle</a
    >
  </div>
</template>

<script>
export default {
  name: "Step5Footer",
  methods: {
    next() {
      this.$eventBus.$emit("validateCompraTraeStep5");
    },
  },
  computed: {
    totalFare: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_FARE;
      },
    },
  },
};
</script>

<style></style>
