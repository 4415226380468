<template>
  <div style='position: relative; width: 100%; height: 56px; display: flex; align-items: center'>
    <button
      ref='myButton'
      :type='buttonType'
      class="custom-button"
      :class="{ isLoading: isLoading, isDisabled: isDisabled, 'clicked': isClicked, [variant]: variant, [size]: size }"
      @mousedown='handleMousedown' @mouseup='handleMouseup' @click='handleClick'
      :style="computedStyles"
    >
      <RingLoader v-if="isLoading" />
      <slot v-if="$slots['icon']" name='icon'></slot>
      <span v-if="$slots['custom-content']" class="button_label">
        <slot :class="'button_label'" name='custom-content'></slot>
      </span>
      <span v-if="label" class="button_label">{{ label }}</span>
    </button>
    <div
      v-if="variant === ''"
      class='layer'
      :class="{ isLoading: isLoading, isDisabled: isDisabled, 'clicked': isClicked, [variant]: variant, [size]: size }"
      @mousedown='handleMousedown' @mouseup='handleMouseup' @click='handleClickFromLayer'
    >
    </div>
  </div>
</template>

<script>
import RingLoader from '../Loaders/RingLoader.vue';

export default {
  data() {
    return {
      isClicked: false
    }
  },
  components: {
    RingLoader,
  },
  props: {
    buttonType: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'l'
    },
    label: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isIconOnly: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    handleClickFromLayer() {
      if(this.isLoading || this.isDisabled) return
      this.$refs.myButton.click()
    },
    handleClick() {
      if(this.isLoading || this.isDisabled) return
      this.$emit('click');
    },
    handleMousedown() {
      if(this.isLoading || this.isDisabled) return
      this.isClicked = true
      document.addEventListener('mouseup', this.handleMouseup)
    },
    handleMouseup() {
      if(this.isLoading || this.isDisabled) return
      this.isClicked = false
      document.removeEventListener('mouseup', this.handleMouseup)
    }
  },
  computed: {
    computedStyles() {
      return {
        padding: this.isIconOnly ? '12px' : '12px 24px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.l { height: 56px; border-radius: 12px }
.m { height: 40px; border-radius: 12px }
.s { height: 32px; border-radius: 8px }

.custom-button {
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  transition: .2s;

  width: 100%;
  margin: 0 !important;

  background: #121212;
}
.button_label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  display: flex;
}

.secondary {
  background: #121212 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  &:not(.isLoading):not(.isDisabled):not(.clicked):hover {
    background: #414141 !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
  }
  &.clicked {
    background: #737373 !important;
    box-shadow: none !important;
  }
  &.isLoading, &.isDisabled {
    cursor: default;
    opacity: .4;
    box-shadow: none;
  }
}
.secondary-inverse {
  background: #FFF !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  &:not(.isLoading):not(.isDisabled):not(.clicked):hover {
    background: #EBEBEB !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
  }
  &.isLoading, &.isDisabled {
    cursor: default;
    opacity: .6;
    background: #EBEBEB !important;
    box-shadow: none;
  }
  &.clicked {
    background: #A0A0A0 !important;
    box-shadow: none;
  }
  .button_label { color: #121212 }
}
.ghost {
  background: transparent !important;
  border: 1px solid #121212;
  &:not(.isLoading):not(.isDisabled):not(.clicked):hover { background: rgba(0, 0, 0, 0.08) !important }
  &.isLoading, &.isDisabled { cursor: default; opacity: .5 }
  &.clicked { background: rgba(0, 0, 0, 0.16) !important }
  .button_label { color: #121212 }
}
.tertiary {
  background: transparent !important;
  &:not(.isLoading):not(.isDisabled):not(.clicked):hover { background: rgba(0, 0, 0, 0.08) !important }
  &.isLoading, &.isDisabled { cursor: default; opacity: .4 }
  &.clicked { background: rgba(0, 0, 0, 0.16) !important }
  .button_label {
    color: #121212;
    text-decoration: underline;
    text-underline-offset: 2px
  }
}

.layer {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: transparent;
  transition: .2s;
  border-radius: 12px;
  background-color: #fff;
  opacity: 0;

  &:not(.isLoading):not(.isDisabled):not(.clicked):hover {
    opacity: .20;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
  }

  &.isLoading, &.isDisabled {
    cursor: default;
    opacity: .7;
  }

  &.clicked {
    background-color: #FFF;
    opacity: .41;
  }
}
</style>