<template>
  <div class="c-slider pt-0">
    <VueSlickCarousel v-bind="settingSlider">
      <div class="c-slider_item">
        <img src="~@/assets/img/service-cart-welcome.png" />
        <div class="">
          {{ textCarousel }}
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  data() {
    return {
      settingSlider: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 6000,
        cssEase: "linear",
      },
    };
  },
  components: {
    VueSlickCarousel,
  },
  computed: {
    progressNav() {
      return this.$store.getters.EXPRESS_PROGRESS_NAV;
    },
    textCarousel() {
      switch (this.progressNav.step) {
        case 1:
          return "Cuéntanos de dónde a dónde llevaremos tu paquete";

        case 2:
          return "¡No olvides agregar toda la información del paquete! ";

        case 3:
          return "Chequea y verifica el resumen de tu envío";

        default:
          return "Recogemos tus paquetes y los enviamos a todo Lima y provincias.";
      }
    },
  },
  methods: {},
  mounted() {},
};
</script>
