<template>
  <div
    class="address_container"
    :class="{ 'clicked': isClicked }"
    @click="handleClick"
    @mousedown="startZoom"
    @mouseup="endZoom"
  >
    <div v-if="addressData.description" class="avatar-and-select-text">
      <img src="./pin.svg" alt="Mi imagen1">
      <div>
        <div class="text-m-regular">{{ addressData.description }}</div>
        <div class="text-m-regular">{{ addressData.departmentName }}, {{ addressData.provinceName }}, {{ addressData.districtName }}</div>
        <div v-if='addressData.name' class='text-s-regular' style='color: #737373'>{{ addressData.name }}</div>
      </div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m7.5 5 5 5-5 5" stroke="#1A1A1A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div v-else class="avatar-and-select-text">
      <!-- Avatar -->
      <img src="./pin.svg" alt="Mi imagen2">

      <!-- Label -->
      <div class="text-m-regular">Escoge una dirección</div>
      <svg class="svg-left" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m7.5 5 5 5-5 5" stroke="#1A1A1A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addressData: {
      default: null
    },
  },
  data() {
    return {
      attributeIsMissing: false,
      isClicked: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
    startZoom() {
      this.isClicked = true
      document.addEventListener('mouseup', this.endZoom)
    },
    endZoom() {
      this.isClicked = false
      document.removeEventListener('mouseup', this.endZoom)
    }
  },
}
</script>

<style lang="scss" scoped>
.address_container {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-items: center;
  padding: 16px;

  width: 100%;

  background: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 12px;
  transition: .3s;

  &:hover {
    border: 1px solid transparent;
    background: rgba(0, 0, 0, 0.08);
  }
  &.clicked {
    background: rgba(0, 0, 0, 0.20);
  }
  .avatar-and-select-text {
    width: 100%;
    display: grid;
    grid-template-columns: 24px 1fr 20px;
    gap: 16px;
    align-items: center;
    .svg-left { justify-self: center }
    .select-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1A1A1A;

      display: -webkit-box;
      -webkit-line-clamp: 2; /* Número máximo de líneas permitidas */
      -webkit-box-orient: vertical;

      /*  white-space: nowrap; */
      overflow: hidden; /* Ocultar el texto que excede el contenedor */
      text-overflow: ellipsis;
    }
  }
}
</style>